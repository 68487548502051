import * as React from "react";
import utility from "../../classes/utility";
import { Config } from "../../classes/config";
import AlertDialog from "../common/alertDialog";
import AssetSearch from "../AssetSearch/AssetSearch";
import AssetMap from "../AssetSearch/AssetMap";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import DeleteIcon from "@material-ui/icons/Delete";
import MapIcon from "@material-ui/icons/Map";
import Paper from "@material-ui/core/Paper";
import { DropzoneComponent } from "react-dropzone-component";
import Spinner from "../common/reusableComponents/Spinner";
import DateBoxInput from "../common/reusableComponents/DateBox";
import SelectBoxInput from "../common/reusableComponents/SelectBox";
import TextBoxInput from "../common/reusableComponents/TextBox";
import CheckBoxInput from "../common/reusableComponents/CheckBox";
import TextAreaInput from "../common/reusableComponents/TextArea";
import { Label } from "devextreme-react/form";
import DevExtremeAccordion from "devextreme-react/accordion";
import { TextArea } from "devextreme-react/text-area";
import DataSource from "devextreme/data/data_source";
import { SelectBox } from "devextreme-react/select-box";
import global from "../../classes/global";
import InspectionForm from "../InspectionForm/InspectionForm";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
let myDropzone: any;

export default class CreateWO extends React.Component<any, any> {
  public Assets: any = [];
  public defaultAsset: any;
  public uploadedFiles: any = [];
  public dataURL: any = [];
  public currentUser: any;
  public responseData: any = [];
  public setupDataPromise: any = [];
  public treeView: any;
  constructor(props: any) {
    super(props);
    this.treeView = null;
    this.uploadedFiles = [];
    this.dataURL = [];
    this.Assets = [];
    this.defaultAsset = "";
    this.setupDataPromise = [];
    this.clearFilter = this.clearFilter.bind(this);
    this.createWorkOrder = this.createWorkOrder.bind(this);
    this.SearchClicked = this.SearchClicked.bind(this);
    let currentDetail = localStorage.getItem("currentDetail");

    this.currentUser = {
      Email: currentDetail,
    };

    let endDate = new Date();
    endDate.setHours(endDate.getHours() + 1);

    this.state = {
      location: "",
      suburb: "",
      WRTType: "",
      title: "",
      Eststartdate: new Date(),
      Estenddate: new Date(endDate),
      befpicreq: false,
      aftpicreq: false,
      riskassessment: false,
      isWorkorderOvertime: false,
      crewteam: "",
      comments: "",
      description: "",
      Assets: this.Assets,
      defaultAsset: this.defaultAsset,
      folderName: "",
      departmentID: "",
      failureItem: [],
      assetType: "",
    };
  }
  public async componentDidMount() {
    this.setState({
      location: this.props.data ? this.props.data[0].location : "",
      Assets: this.props?.Assets ? this.props.Assets : [],
      defaultAsset: this.props?.Assets ? this.props.Assets[0] : "",
      comment: this.props.data ? this.props.data[0].comments : "",
      description: this.props.data
        ? this.props.data[0].workRequestDescription
        : "",
    });

    if (
      this.props.Assets &&
      this.props.Assets.length > 0 &&
      this.props?.Assets[0].maintenanceAssetType
    ) {
      this._getFCRData(this.props?.Assets[0].maintenanceAssetType);
    }
    if (
      this.props.data &&
      Array.isArray(this.props.data) &&
      this.props.data.length > 0 &&
      Array.isArray(this.props.data[0].assetIds) &&
      this.props.data[0].assetIds !== null
    ) {
      this.loadAssetDetails(this.props.data[0].assetIds);
    }
    await Promise.all([
      this._getworkGroupData(),
      this._getDepartmentData(),
      this.getWOSchedulerDate(),
      this._getTeamDataAsync(),
      this._getworkTypeDataAsync(false),
      this._getCrewDataAsync(),
      this.getUserdata(),
      this._getPrioritiesData(),
      this._getInspectionFormsData(),
    ]);

    this.setState({
      loadingFields: true,
      showServiceReq: this.props.showServiceReq,
      WRdata: this.props.data,
    });
  }
  private async _getPrioritiesData() {
    try {
      let apiName = `${Config.PriorityListName}?showAll=true`;
      const data: any = await utility.genericGetAPICall(apiName);
      const filteredItems = data
        .filter((r: any) => r.isActive && r.type === "WorkOrder")
        .map((r: any) => ({ id: r.id, text: r.title }));

      const sortedItems = utility._sortItems(filteredItems, "text", false);
      this.setState({
        priorityItems: sortedItems,
        allpriorityItems: sortedItems,
      });
    } catch (err) {
      console.log(err);
    }
  }
  public async getUserdata() {
    let dataToPassToService: any = {};
    try {
      const data: any = await utility.genericGetAPICallForList(
        Config.SearchEmployeeApi,
        "POST",
        dataToPassToService
      );
      let _items = [];
      _items = data
        .filter((r: any) => r.isActive === true)
        .map((r: any) => ({
          id: r.id,
          text: r.fullName,
          departmentId: r.departmentId,
          crewId: r.crewId,
          teamId: r.teamId,
        }));

      _items = utility._sortItems(_items, "text", false);

      this.setState({
        useritems: _items,
        callAfterInit: true,
        loading: false,
        alluseritems: _items,
      });
    } catch (err) {
      console.log(err);
    }
  }
  public loadAssetDetails(AsseticIDs: any) {
    this.setState({ loadingAssetDetail: true });
    let dataToPassToService: any = {};
    dataToPassToService.assetIds = AsseticIDs;
    this.setupDataPromise.push(
      utility
        .genericPostAPIcall(Config.SearchAssetDetails, dataToPassToService)
        .then((data: any) => {
          let assetDetailsItems: any[] = [];
          if (data.length > 0) {
            data.forEach((item: any) => {
              assetDetailsItems.push({
                Register: item.registerTitle,
                AssetNumber: item.id,
                AssetID: item.id,
                id: item.id,
                decommisionDate: item.decommisionDate,
                AssetType: item.typeTitle,
                AssetTypeId: item.typeId,
                activityId: item.activityId,
                taskId: item.taskId,
                locationId: item.locationId,
                AssetClass: item.classTitle,
                workGroupIds: item.workGroupIds,
                AssetName: item.title,
                erpAssetId: item.erpAssetId,
                erpExtAssetId: item.erpExtAssetId,
                ServiceStatus: item.assetStatus,
                Description: item.description,
                location: item.locationDescription,
                maintenanceAssetType: item.maintenanceAssetType,
                Category: item.assetCategoryTitle,
                assetCriticality: item.assetCriticality,
                departmentTitle: item.departmentTitle,
                assetsubclass: item.subclassTitle,
                teamTitle: item.teamTitle,
                typeTitle: item.typeTitle,
                subtypeTitle: item.subtypeTitle,
                accntSystemName: item.accntSystemName,
                accntSystemId: item.accntSystemId,
                barcode: item.barcode,
                assetLastModified: item.assetLastModified,
                projectCode: item.projectCode,
                erpExtSystemName: item.erpExtSystemName,
                functionalLocationL1Id: item.functionalLocationL1Id,
                functionalLocationL1Name: item.functionalLocationL1Name,
                functionalLocationL1Type: item.functionalLocationL1Type,
                functionalLocationL2Id: item.functionalLocationL2Id,
                functionalLocationL2Name: item.functionalLocationL2Name,
                functionalLocationL2Type: item.functionalLocationL2Type,
                parentAssetId: item.parentAssetId,
                masterAssetId: item.masterAssetId,
                assetStatusDate: item.assetStatusDate,
                Selected: false,
              });
              this._getworkTypeDataAsync(
                item.workGroupIds,
                item.accntSystemId,
                item.activityId,
                item.taskId
              );
            });

            this.setState({
              Assets: assetDetailsItems[0],
              loadingAssetDetail: false,
            });
          } else {
            this.setState({
              assetdetails: [],
              loadingAssetDetail: false,
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
          this.setState({ assetdetails: [], loadingAssetDetail: false });
        })
    );
  }
  public clearFilter() {
    myDropzone.removeAllFiles(true);
    let endDate = new Date();
    endDate.setHours(endDate.getHours() + 1);
    this.setState({
      serviceRequest: "",
      leadinghand: "",
      location: "",
      department: "",
      WRTType: "",
      priority: null,
      Eststartdate: new Date(),
      Estenddate: new Date(endDate),
      befpicreq: false,
      aftpicreq: false,
      riskassessment: false,
      isWorkorderOvertime: false,
      crewteam: "",
      team: "",
      assetlocation: "",
      comment: "",
      uploadedFiles: "",
      Assets: [],
      defaultAsset: "",
      lat: "",
      lng: "",
      Failure: "",
      Cause: "",
      Remedy: "",
      InspectionForm: "",
      WRTTypeItems: this.state.allWRTTypeItems,
      departmentItems: this.state.alldepartmentItems,
      tempTeamItems: this.state.teamsItems,
      tempCrewItems: this.state.crewItems,
      disableCrew: true,
      useritems: this.state.alluseritems,
      FailureItems: [],
      CauseItems: [],
      RemedyItems: [],
      InspectionFormItems: [],
    });
    this.Assets = [];
    if (this.props.newWOModal) {
      this.props.onClose();
    }
  }
  public async getWOSchedulerDate() {
    if (this.props.startDate && this.props.endDate) {
      this.setState({
        crewteam: { id: this.props.crewId, text: this.props.crewName },
        Eststartdate: new Date(this.props.startDate),
        Estenddate: new Date(this.props.endDate),
      });
    }
  }
  private async _getTeamDataAsync() {
    try {
      const data: any = await utility.genericGetAPICall(
        `${Config.TeamList}?showAll=true`
      );
      const teamsItems = data
        .filter((r: any) => r.isActive === true)
        .map((r: any) => ({
          id: r.id,
          text: r.title,
          departmentId: r.departmentId,
        }));
      const sortedItems = utility._sortItems(teamsItems, "text", false);
      this.setState({ teamsItems: sortedItems, tempTeamItems: sortedItems });
    } catch (err) {
      console.log(err);
    }
  }
  private async _getDepartmentData() {
    try {
      const data: any = await utility.genericGetAPICall(
        `${Config.DepartmentList}?showAll=true`
      );
      const departmentItems = data
        .filter((r: any) => r.isActive === true)
        .map((r: any) => ({ id: r.id, text: r.title }));
      const sortedItems = utility._sortItems(departmentItems, "text", false);
      this.setState({
        departmentItems: sortedItems,
        alldepartmentItems: sortedItems,
      });
    } catch (err) {
      console.log(err);
    }
  }
  private async _getworkGroupData() {
    try {
      const data: any = await utility.genericGetAPICall(Config.workGroup);
      let selectedWG: any = [];
      const workGroupItems = data.map((r: any) => ({
        id: r.id,
        text: r.title,
        description: r.description,
        erpId: r.erpId,
        defaultSkillId: r.defaultSkillId,
        "Select All": r.title,
      }));
      const sortedItems = utility._sortItems(workGroupItems, "text", false);
      this.setState({
        workGroupItems: sortedItems,
        allworkGroupItems: sortedItems,
        workGroup: selectedWG,
      });
    } catch (err) {
      console.log(err);
    }
  }
  private async _getCrewDataAsync() {
    try {
      const data: any = await utility.genericGetAPICall(
        `${Config.crewswithdepartment}?showAll=true`
      );
      const crewItems = data
        .filter((r: any) => r.isActive === true)
        .map((r: any) => ({
          id: r.id,
          text: r.title,
          teamId: r.teamId,
          departmentId: r.departmentId,
        }));
      const sortedItems = utility._sortItems(crewItems, "text", false);
      this.setState({
        crewItems: sortedItems,
        tempCrewItems: sortedItems,
      });
    } catch (err) {
      console.log(err);
    }
  }
  private async _getworkTypeDataAsync(
    isAssetSelected: any,
    workGroupIds?: any,
    projectIds?: any,
    activitys?: any,
    taskIds?: any
  ) {
    let workGroupId = workGroupIds
      ? workGroupIds
      : this.props.Assets !== undefined && this.props.Assets.length > 0
        ? this.props.Assets[0]?.workGroupIds
        : "";
    let projectId = projectIds
      ? projectIds
      : this.props.Assets !== undefined && this.props.Assets.length > 0
        ? this.props?.Assets[0]?.accntSystemId
        : "";
    let taskId = taskIds
      ? taskIds
      : this.props.Assets !== undefined && this.props.Assets.length > 0
        ? this.props?.Assets[0]?.taskId
        : "";
    let activity = activitys
      ? activitys
      : this.props.Assets !== undefined && this.props.Assets.length > 0
        ? this.props?.Assets[0]?.activityId
        : "";
    let sortedItems: any = [];
    let filtereddata;
    try {
      const data: any = await utility.genericGetAPICall(
        `${Config.WTlistname}?showAll=true`
      );
      let isActivity: boolean = false;
      filtereddata = workGroupId
        ? data.filter((f: any) => {
          const hasWorkGroupIds = f.workGroupIds && f.workGroupIds.length > 0;
          const hasMatchingWorkGroup =
            hasWorkGroupIds &&
            f.workGroupIds.some((value: any) => workGroupId.includes(value));
          const isMatchingCategory = f.category === global.filterWOType;
          const hasMatchingProject = projectId
            ? f.projectId === projectId
            : true;
          const hasMatchingActivity = activity
            ? f.activity === activity
            : true;
          const hasMatchingTask = taskId ? f.taskId === taskId : true;
          if (workGroupId) {
            isActivity = hasMatchingWorkGroup && isMatchingCategory;
            if (projectId !== "" || projectId !== null) {
              isActivity = isActivity && hasMatchingProject;
            }
            if (activity !== "" || projectId !== null) {
              isActivity = isActivity && hasMatchingActivity;
            }
            if (taskId !== "" || projectId !== null) {
              isActivity = isActivity && hasMatchingTask;
            }
            return isActivity;
          } else {
            return isMatchingCategory;
          }
        })
        : data.filter((f: any) => f.category === global.filterWOType);
      const allWorkTypeItems = data.filter(
        (f: any) => f.category === global.filterWOType
      );

      // Map function to create items in the desired structure
      const mapItem = (item: any) => ({
        id: item.id,
        text: item.title,
        categoryId: item.workRequestTypeId,
        category: item.category,
        befpicreq: item.photoRequiredBefore ? true : false,
        aftpicreq: item.photoRequiredAfter ? true : false,
        riskassessment: item.riskAssessmentRequired ? true : false,
        departmentId: item.departmentId,
        teamId: item.teamIds,
        workGroupId: item.workGroupIds,
        workRequestTypeId: item.workRequestTypeId,
        gisLayers: item.gisLayers,
      });

      const WRTTypeItems = filtereddata.map(mapItem);
      const allMappedWorkTypeItems = allWorkTypeItems.map(mapItem);

      const sortedItems = utility._sortItems(WRTTypeItems, "text", false);
      const sortedAllItems = utility._sortItems(
        allMappedWorkTypeItems,
        "text",
        false
      );

      this.setState({
        WRTTypeItems: sortedItems,
        allWRTTypeItems: sortedAllItems,
      });
      if (sortedItems && sortedItems.length === 1) {
        const selectedeprt = this.state.alldepartmentItems.filter(
          (d: any) => d.id === sortedItems[0].departmentId
        );
        const selectedteam = this.state.teamsItems.filter((d: any) =>
          sortedItems[0].teamId.includes(d.id)
        );
        const teamArray = selectedteam.map((item: any) => item.id);
        const selectedcrew = this.state.crewItems.filter((d: any) =>
          teamArray.includes(d.teamId)
        );
        const selectedusers = this.state.alluseritems.filter((d: any) =>
          teamArray.includes(d.teamId)
        );
        this.setState({
          WRTType: sortedItems[0],
          departmentItems: selectedeprt,
          department: selectedeprt ? selectedeprt[0] : "",
          tempTeamItems: selectedteam,
          team:
            selectedteam && selectedteam.length === 1 ? selectedteam[0] : "",
          tempCrewItems: selectedcrew,
        });
      } else if (sortedItems.length <= 0) {
        throw new Error(
          "Work Group is not available for this Asset. Please choose a different Asset."
        );
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
  private processFCRCode(codes: any, FailureItems: any[], CauseItems: any[]) {
    const category = codes.code + " - " + codes.title;

    if (codes.failureSubCodes) {
      codes.failureSubCodes.forEach((subCode: any) => {
        FailureItems.push({
          Category: category,
          ID: subCode.id,
          Name: codes.code + "," + subCode.code + " - " + subCode.title,
        });
      });
    }

    if (codes.causeSubCodes) {
      codes.causeSubCodes.forEach((causeSubCode: any) => {
        CauseItems.push({
          Category: category,
          ID: causeSubCode.id,
          Name:
            codes.code + "," + causeSubCode.code + " - " + causeSubCode.title,
          isDefault: causeSubCode.isDefault,
        });
      });
    }
  }
  private async _getFCRData(maintenanceType: string) {
    const api = Config.FcrCategorisation + maintenanceType;
    const FailureItems: any[] = [];
    const CauseItems: any[] = [];
    const RemedyItems: any[] = [];
    try {
      const data: any = await utility.genericGetAPICallForList(api);
      if (data.failureCodes !== null) {
        data.failureCodes.forEach((failureCode: any) => {
          this.processFCRCode(failureCode, FailureItems, CauseItems);
        });

        data.causeCodes.forEach((causeCode: any) => {
          this.processFCRCode(causeCode, FailureItems, CauseItems);
        });
        let remedy;
        data.remedyCodes.forEach((remedyCode: any) => {
          RemedyItems.push({
            id: remedyCode.id,
            text: remedyCode.code + " - " + remedyCode.title,
            isDefault: remedyCode.isDefault,
          });
          if (remedyCode.isDefault === true) {
            remedy = {
              id: remedyCode.id,
              text: remedyCode.code + " - " + remedyCode.title,
              isDefault: remedyCode.isDefault,
            };
          }
        });

        const fromUngroupedData = new DataSource({
          store: {
            type: "array",
            data: FailureItems,
            key: "ID",
          },
          group: "Category",
        });

        const fromCauseUngroupedData = new DataSource({
          store: {
            type: "array",
            data: CauseItems,
            key: "ID",
          },
          group: "Category",
        });
        const Cause = CauseItems.filter((item: any) => item.isDefault).map(
          (r: any) => r.ID
        );
        this.setState({
          FailureItems: fromUngroupedData,
          CauseItems: fromCauseUngroupedData,
          RemedyItems,
          Remedy: remedy ? remedy : "",
          Cause: Cause ? Cause[0] : "",
        });
      } else {
        this.setState({
          alertMessage:
            "FCR is not available for this Asset. Please choose different Asset.",
          showAlert: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  private async _getInspectionFormsData() {
    try {
      const data: any = await utility.genericGetAPICall(
        `${Config.FormTypes}?showAll=true`
      );
      const formItems = data.map((r: any) => ({
        id: r.id,
        text: r.title,
      }));
      const sortedItems = utility._sortItems(formItems, "text", false);
      this.setState({
        InspectionFormItems: sortedItems,
      });
    } catch (err) {
      console.log(err);
    }
  }
  private _closeDialog = (): void => {
    this.setState({ alertMessage: "", showAlert: false });
    if (this.state.previousPage) {
      if (this.props.newWOModal) {
        this.props.onClick();
      } else {
        let location = window.location.origin + window.location.pathname;
        let SearchWO = `${location}#/WorkOrderDetail?WOID=${this.state.woData.id}`;
        window.open(SearchWO, "_self");
      }
    }
  };
  public handleEvent(file: any) {
    this.onDropGeneral(file);
    this.uploadedFiles.push({ file });
    this.setState({ uploadedFile: this.uploadedFiles });
  }
  public onDropGeneral(file: any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      this.dataURL.push({
        file: file.name,
        dataUrl: event ? event.currentTarget : "",
      });
    };
  }
  public createWorkOrder() {
    let accpeted;
    if (this.uploadedFiles) {
      this.uploadedFiles.forEach((item: any) => {
        if (item.file.accepted === false) {
          accpeted = false;
        }
      });
      if (accpeted === false) {
        this.setState({
          alertMessage:
            "Selected file is not accepted. Please select another file.",
          showAlert: true,
        });
        return;
      }
    }
    if (!this.state.WRTType) {
      this.setState({
        alertMessage: "Please select Work Order Type",
        showAlert: true,
      });
      return;
    }
    if (this.state.FailureItems && !this.state.Failure) {
      this.setState({
        alertMessage: "Please select Failure",
        showAlert: true,
      });
      return;
    }
    if (this.state.CauseItems && !this.state.Cause) {
      this.setState({
        alertMessage: "Please select Cause",
        showAlert: true,
      });
      return;
    }
    if (this.state.RemedyItems && !this.state.Remedy) {
      this.setState({
        alertMessage: "Please select Remedy",
        showAlert: true,
      });
      return;
    }
    if (!this.state.location) {
      this.setState({
        alertMessage: "Please enter Location",
        showAlert: true,
      });
      return;
    }
    if (!this.state.department) {
      this.setState({
        alertMessage: "Please select Department",
        showAlert: true,
      });
      return;
    }
    if (this.state.Eststartdate && this.state.Estenddate) {
      let Eststartdate = new Date(this.state.Eststartdate);
      let Estenddate = new Date(this.state.Estenddate);
      if (Estenddate.getTime() < Eststartdate.getTime()) {
        this.setState({
          alertMessage:
            "Scheduled Start Date should be greater than Scheduled Finish Date",
          showAlert: true,
        });
        return;
      }
    }
    if (!this.state.Assets) {
      this.setState({
        alertMessage: "Please select Asset.",
        showAlert: true,
      });
      return;
    }
    if (!this.state.description) {
      this.setState({
        alertMessage: "Please provide Description.",
        showAlert: true,
      });
      return;
    }
    this.setState({
      alertMessage: <Spinner label="Creating work order" />,
      hideAlertButton: true,
      showAlert: true,
    });

    let dataToPassToService: any = {};
    if (this.props.data) {
      dataToPassToService.workRequestId = this.props.data[0].id;
    }
    if (
      this.state.Eststartdate &&
      this.state.Estenddate &&
      this.state.crewteam
    ) {
      dataToPassToService.workorderStatusId = 2;
    } else {
      dataToPassToService.workorderStatusId = 5;
    }

    dataToPassToService.title = this.state.WRTType.text;
    dataToPassToService.description = this.state.description
      ? this.state.description
      : "";
    dataToPassToService.workorderTypeId = this.state.WRTType?.id;
    dataToPassToService.departmentId = this.state.department?.id;
    dataToPassToService.priorityId = this.state.priority?.id;
    dataToPassToService.workorderLocation = this.state.location;
    dataToPassToService.comments = this.state.comment;
    dataToPassToService.failureCodeId = this.state.Failure
      ? this.state.Failure.ID
      : "";
    dataToPassToService.causeCodeId = this.state.Cause
      ? this.state.Cause.ID
      : "";
    dataToPassToService.remedyCodeId = this.state.Remedy
      ? this.state.Remedy.id
      : "";
    dataToPassToService.inspectionFormId = this.state.InspectionForm
      ? this.state.InspectionForm.id
      : "";
    dataToPassToService.dateScheduledStart = this.state.Eststartdate ? new Date(
      this.state.Eststartdate).toISOString() : null;
    dataToPassToService.dateScheduledEnd = this.state.Estenddate ? new Date(this.state.Estenddate).toISOString() : null;
    dataToPassToService.crewId = this.state.crewteam
      ? this.state.crewteam.id
      : null;
    dataToPassToService.leadingHandId = this.state.leadinghand
      ? this.state.leadinghand.id
      : null;
    dataToPassToService.riskAssessmentRequired = this.state.riskassessment
      ? true
      : false;
    dataToPassToService.isWorkorderOvertime = this.state.isWorkorderOvertime
      ? true
      : false;
    dataToPassToService.photoRequiredBefore = this.state.befpicreq
      ? true
      : false;
    dataToPassToService.photoRequiredAfter = this.state.aftpicreq
      ? true
      : false;
    dataToPassToService.teamId = this.state.team ? this.state.team.id : null;
    dataToPassToService.tenantId = Config.tenantId;
    dataToPassToService.locationLatLong = {
      x: this.state.latLong ? this.state.latLong.lng : null,
      y: this.state.latLong ? this.state.latLong.lat : null,
    };
    dataToPassToService.workorderLocation = this.state.location;
    dataToPassToService.created = new Date();
    dataToPassToService.updated = new Date();
    if (this.state.Assets && this.state.Assets.length > 0) {
      // //Search List
      // if (this.state.mode === "SearchAssetList") {
      //   dataToPassToService.assetIds = this.state.Assets.map(
      //     (asset: any) => asset.AssetID
      //   );
      // }
      // //Map
      // if (this.state.mode === "MapList") {
      //   dataToPassToService.erpAssetId = this.state.Assets.Assetic_ID
      //     ? this.state.Assets.Assetic_ID
      //     : "";
      // }
      dataToPassToService.erpAssetIds = this.state.Assets.map((asset: any) =>
        asset.erpAssetId ? asset.erpAssetId : asset.AsseticAssetID
      );
    }
    dataToPassToService.createdBy = this.currentUser.Email;
    dataToPassToService.updatedBy = this.currentUser.Email;
    utility
      .genericPostAPIcall(Config.workorderlistname, dataToPassToService)
      .then(
        (data: any) => {
          this.setState({ woData: data });
          if (this.uploadedFiles.length > 0) {
            this.getWorkOrderData(data);
          } else {
            this.setState({
              alertMessage: "Work order (" + data.id + ") has been created",
              showAlert: true,
              hideAlertButton: false,
              previousPage: true,
            });
            this.clearFilter();
          }
          // if (this.props.data) {
          //   this.updateWRStatus(this.props.data[0].id);
          // }
        },
        (err: any) => {
          console.log(err);
          this.setState({
            alertMessage: "Something went wrong. Please try again",
            showAlert: true,
            hideAlertButton: false,
            loading: false,
          });
        }
      );
  }
  public updateWRStatus(id: any) {
    let dataToPassToService: any = {};
    dataToPassToService.workRequestStatusId = 3;
    dataToPassToService.id = id;
    utility
      .genericUpdateAPICall(Config.WRList, id, dataToPassToService)
      .then((results: any) => { });
  }
  public getWorkOrderData(wodata: any) {
    this.setState({
      alertMessage: <Spinner label="Uploading documents..." />,
      hideAlertButton: true,
      showAlert: true,
    });
    let d = new Date();
    let amOrPm = d.getHours() < 12 ? "AM" : "PM";
    let hour = d.getHours() < 12 ? d.getHours() : d.getHours() - 12;
    let date =
      d.getDate() +
      "_" +
      d.getMonth() +
      "_" +
      d.getFullYear() +
      "_" +
      hour +
      "_" +
      amOrPm;
    let file = this.uploadedFiles;
    for (var i = 0; i < file.length; i++) {
      let fileExtension;
      let tempFileExt = file[i].file.name.split(".");
      tempFileExt.reverse();
      fileExtension = `.${tempFileExt[0]}`;
      let fileName = tempFileExt[1];
      let tempData = this.dataURL[i].dataUrl.result.split("base64,");
      let data = tempData[1];
      let dataToPassToServiceForDoc: any = {};
      dataToPassToServiceForDoc.fileName =
        fileName + "_" + wodata.id + "_" + date + "_" + fileExtension;
      dataToPassToServiceForDoc.documentType = "Photo";
      dataToPassToServiceForDoc.workorderId = wodata.id;
      dataToPassToServiceForDoc.data = data;
      dataToPassToServiceForDoc.tenantId = Config.tenantId;
      dataToPassToServiceForDoc.updatedBy = this.currentUser.Email;
      dataToPassToServiceForDoc.updated = new Date();
      dataToPassToServiceForDoc.created = new Date();
      dataToPassToServiceForDoc.createdBy = this.props.currentUser.Email;
      utility
        .genericPostAPIcall(
          Config.workorderDocumentList,
          dataToPassToServiceForDoc
        )
        .then(
          (data: any) => {
            this.setState({
              alertMessage: "Work order (" + wodata.id + ") has been created",
              showAlert: true,
              hideAlertButton: false,
              loading: false,
              previousPage: true,
            });
            this.clearFilter();
          },
          (err: any) => {
            console.log(err);
            this.setState({
              alertMessage: "Something went wrong. Please try again",
              showAlert: true,
              hideAlertButton: false,
            });
          }
        );
    }
    // });
  }
  public deleteFile(file: any) {
    var deletingIndex = 0;
    this.uploadedFiles.forEach((element: any, index: any) => {
      if (element.file.name === file.name) {
        deletingIndex = index;
      }
    });
    this.uploadedFiles.splice(deletingIndex, 1);
    this.dataURL.forEach((element: any, index: any) => {
      if (element.file.name === file.name) {
        deletingIndex = index;
      }
    });
    this.dataURL.splice(deletingIndex, 1);
  }
  public renderAssetPanel() {
    return (
      <div>
        <Popup
          width={"80vw"}
          maxHeight={"85vh"}
          minHeight={200}
          height={"auto"}
          showTitle={true}
          showCloseButton={true}
          dragEnabled={true}
          hideOnOutsideClick={false}
          visible={this.state.showPanel}
          closeOnOutsideClick={true}
          onVisibleChange={() => this.setState({ showPanel: false })}
        >
          <ScrollView
            width="100%"
            height="100%"
            showScrollbar="onScroll"
            scrollByThumb={true}
          >
            <AssetSearch
              selectMode={true}
              onClick={(Asset: any) => this.selectAssets(Asset)}
              heading={false}
              selectWO={true}
              hideAssetMapButton={true}
              myhttpclient={this.props.myhttpclient}
              aadHttpClient={this.props.aadHttpClient}
              onAssetsSelected={(Asset: any) => this.selectAssets(Asset)}
            />
          </ScrollView>
        </Popup>
      </div>
    );
  }
  public CustomItem(data: any) {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                Request #
              </Grid>
              <Grid item xs={12} sm={8} className={"semiBold"}>
                {data.id}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                Request Type
              </Grid>
              <Grid item xs={12} sm={8} className={"semiBold"}>
                {data.workRequestTypeTitle}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                Request Status
              </Grid>
              <Grid item xs={12} sm={8} className={"semiBold"}>
                {data.workRequestStatusTitle}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
  public CustomTitle(data: any, i: any) {
    return (
      <div style={{ width: "100%" }} className="displayFlex">
        <div className="workTypeListItemTitle">Work Request Details</div>
        <div
          className="displayFlex"
          style={{
            float: "right",
            marginRight: "25px",
            width: "47%",
            flexDirection: "row",
            justifyContent: "end",
          }}
        ></div>
      </div>
    );
  }
  public async selectAssets(selectedAssetArray: any) {
    if (!Array.isArray(selectedAssetArray)) {
      selectedAssetArray = [selectedAssetArray];
    }
    if (selectedAssetArray.length > 1) {
      // Extract asset types and check if they are all the same
      const assetTypes = selectedAssetArray.map((asset: any) => asset.AssetType);
      const allSameType = assetTypes.every(
        (type: string, _: any, array: string[]) => type === array[0]
      );
      if (!allSameType) {
        this.setState({
          alertMessage:
            "The selected assets do not all have the same asset type. Please select assets of the same type.",
          showAlert: true,
        });
        return;
      }
    }

    const selectedAssets: any = [...this.state.Assets];
    let isFirstAsset: boolean = selectedAssets.length === 0;
    const initialAssetType = this.state.assetType;
    let duplicateAssets: any[] = [];

    for (let i = 0; i < selectedAssetArray.length; i++) {
      let Asset = selectedAssetArray[i];
      // check for duplicates

      if (isFirstAsset) {
        const chosenAssetType = Asset.AssetType;
        const chosenAssetypeID = Asset.erpAssetId;
        try {
          await this._getworkTypeDataAsync(
            true,
            Asset.workGroupIds,
            Asset.accntSystemId,
            Asset.activityId,
            Asset.taskId
          );
        } catch (error) {
          this.setState({
            alertMessage:
              `Work Group is not available for this Asset ${chosenAssetypeID}. Please choose different Asset.`,
            showAlert: true,
          });
          return;
        }

        this._getFCRData(Asset.maintenanceAssetType);
        selectedAssets.push(Asset);
        this.setState({
          defaultAsset: Asset,
          Assets: selectedAssets,
          assetType: chosenAssetType,
          Address: Asset.Location,
          location: Asset.Location,
          latLong: Asset.coordinates,
          mode: "SearchAssetList",
          showPanel: false,
        });
        isFirstAsset = false;
      } else {

        const isDuplicateAsset = selectedAssets.some(
          (existingAsset: any) => (existingAsset.erpAssetId === Asset.erpAssetId) || (existingAsset.AsseticAssetID === Asset.erpAssetId)
        );
        if (isDuplicateAsset) {
          duplicateAssets.push(Asset.erpAssetId);
          this.setState({
            alertMessage: `Asset ID ${duplicateAssets} is already added. Please select a different Asset`,
            showAlert: true,
          });
          continue;
        }

        const assetType = this.state.assetType; // Get the stored assetType
        if (Asset.AssetType === assetType) {
          // If assetType matches, add the asset
          selectedAssets.push(Asset);
          this.setState({
            Assets: selectedAssets,
            mode: "SearchAssetList",
            showPanel: false,
          });
        } else {
          this.setState({
            alertMessage:
              `The selected asset does not match the asset type of the initially selected asset. You can only select assets of the same type (${assetType}). Please choose a different asset.`,
            showAlert: true,
          });
          continue; // skip to next Asset
        }
      }
    }
    this.setState({ showPanel: false });
  }

  public async selectAssetsonMap(selectedAssetArray: any) {
    if (selectedAssetArray.length > 1) {
      // Extract asset types and check if they are all the same
      const assetTypes = selectedAssetArray.map((asset: any) => asset.type);
      const allSameType = assetTypes.every(
        (type: string, _: any, array: string[]) => type === array[0]
      );
      if (!allSameType) {
        this.setState({
          alertMessage:
            "The selected assets do not all have the same asset type. Please select assets of the same type.",
          showAlert: true,
        });
        return;
      }
    }
    let flatCoordinates: any = [];
    let isFirstAsset: boolean = this.state.Assets.length === 0;
    const selectedAssets: any = [...this.state.Assets];
    const initialAssetType = this.state.assetType;
    let duplicateAssets: any[] = [];

    for (let i = 0; i < selectedAssetArray.length; i++) {
      let Asset = selectedAssetArray[i];
      let AssetProperties = JSON.parse(Asset.properties);

      if (isFirstAsset) {
        const chosenAssetType = Asset.type;

        const workgroup = this.state.workGroupItems
          .filter((r: any) => r.text === AssetProperties.Asset_Workgroup)
          .map((item: any) => item.id);
        if (workgroup.length > 0 && workgroup !== null) {
          try {
            await this._getworkTypeDataAsync(
              true,
              workgroup,
              AssetProperties.Ext_Accounting_System_1_ID2,
              AssetProperties.activityId,
              AssetProperties.taskId
            );
          } catch (error) {
            this.setState({
              alertMessage:
                `Work Group is not available for this Asset ${Asset.id}. Please choose different Asset.`,
              showAlert: true,
            });
            return;
          }
        } else {
          this.setState({
            alertMessage: `Work Group is not available for Asset ${Asset.id}. Please choose different Asset.`,
            showAlert: true,
            showPanel: false,
          });
          return;
        }
        this._getFCRData(AssetProperties.Maintenance_Asset_Type);
        let flatCoordinatesArray = AssetProperties?.geometry?.flatCoordinates;
        if (flatCoordinatesArray) {
          flatCoordinatesArray.forEach((element: any, i: any) => {
            if (i % 2 === 0) {
              flatCoordinates.push({
                lat: flatCoordinatesArray[i + 1],
                lng: flatCoordinatesArray[i],
              });
            }
          });
        }

        let Parseddata = JSON.parse(selectedAssetArray[0].properties);
        selectedAssets.push(Asset);
        this.setState({
          defaultAsset: Asset,
          Assets: selectedAssets,
          mode: "MapList",
          assetType: chosenAssetType,
          lat: selectedAssetArray[0].center[1].toString(),
          lng: selectedAssetArray[0].center[0].toString(),
          latLong: flatCoordinates[0],
          location: Parseddata.Location ? Parseddata.Location : "",
          assetlocation: Parseddata.Location + " Adelaide SA Australia",
          iFramePanelOpened: false,
          selectParent: false,
          selectModeMap: false,
        });
        isFirstAsset = false;
      } else {
        // check for duplicates
        const isDuplicateAsset = selectedAssets.some(
          (existingAsset: any) => (existingAsset.AsseticAssetID === Asset.AsseticAssetID) || (existingAsset.erpAssetId === Asset.AsseticAssetID)
        );
        if (isDuplicateAsset) {
          duplicateAssets.push(Asset.AsseticAssetID);
          this.setState({
            alertMessage: `Asset ID ${duplicateAssets} is already added. Please select a different Asset`,
            showAlert: true,
          });
          continue; // Skip to next asset
        }

        // Check if the asset type matches the first asset
        const assetType = this.state.assetType; // Get the stored assetType
        if (Asset.type === assetType) {
          // If assetType matches, add the asset
          selectedAssets.push(Asset);
          this.setState({
            Assets: selectedAssets,
            mode: "MapList",
            iFramePanelOpened: false,
          });
        } else {
          this.setState({
            alertMessage:
              `The selected asset does not match the asset type of the initially selected asset. You can only select assets of the same type (${assetType}). Please choose a different asset.`,
            showAlert: true,
            iFramePanelOpened: false,
          });
          continue; // Skip to next asset
        }
      }
    }
    this.setState({ iFramePanelOpened: false });
  }

  private SearchClicked() {
    this.setState({
      iFramePanelOpened: true,
      selectModeMap: true,
      selectParent: false,
    });
  }
  public renderAssetMap() {
    return (
      <AssetMap
        address={`${this.state.location} Adelaide SA Australia`}
        //address={'Park 12 Adelaide SA Australia'}
        al={this.state.al}
        polylines={false}
        selectAssets={(Asset: any) => this.selectAssetsonMap(Asset)}
        iFramePanelOpened={this.state.iFramePanelOpened}
        onClick={() => this._onDismissMap()}
        selectParent={this.state.selectParent}
        selectModeMap={this.state.selectModeMap}
      />
    );
  }
  public _onDismissMap() {
    this.setState({
      iFramePanelOpened: false,
    });
  }
  public removeAssetNumber(index: number) {
    // Check if the index is valid
    if (index >= 0 && index < this.state.Assets.length) {
      // Create a new array of assets excluding the one at the specified index
      const updatedAssets: any = [...this.state.Assets];
      updatedAssets.splice(index, 1);

      // Check if the removed asset was the last one
      if (updatedAssets.length === 0) {
        let endDate = new Date();
        endDate.setHours(endDate.getHours() + 1);

        this.setState({
          Assets: [],
          defaultAsset: "",
          WRTType: "",
          department: "",
          crewteam: "",
          location: "",
          assetlocation: "",
          Eststartdate: new Date(),
          Estenddate: new Date(endDate),
          befpicreq: false,
          aftpicreq: false,
          riskassessment: false,
          isWorkorderOvertime: false,
          team: "",
          Cause: "",
          Remedy: "",
          InspectionForm: "",
          leadinghand: "",
          WRTTypeItems: this.state.allWRTTypeItems,
          tempTeamItems: this.state.teamsItems,
          departmentItems: this.state.alldepartmentItems,
          tempCrewItems: this.state.crewItems,
          Failure: "",
          FailureItems: [],
          CauseItems: [],
          RemedyItems: [],
          InspectionFormItems: this.state.InspectionFormItems,
        });
      } else {
        // Update the state with the new array of assets
        this.setState({
          Assets: updatedAssets,
        });
      }
    }
  }
  private handleValueChange = (event: any, fieldName: string) => {
    if (fieldName === "Eststartdate" && event.value) {
      let endDate = new Date(event.value);
      endDate.setHours(event.value.getHours() + 1);
      this.setState({
        Estenddate: new Date(endDate),
      });
    }

    this.setState({ [fieldName]: event.value });
  };
  public handleDropdownChange(e: any, fieldName: string) {
    if (fieldName === "WRTType" && e.selectedItem !== null) {
      this.setState({
        departmentItems: [],
        tempTeamItems: [],
        tempCrewItems: [],
        department: "",
        team: "",
        crewteam: "",
      });
      const selectedDeptItem = this.state.alldepartmentItems
        .filter((r: any) => r.id === e.selectedItem.departmentId)
        .map((r: any) => ({ id: r.id, text: r.text }));
      let filteredTeam: any = [];
      this.state.teamsItems.forEach((element: any) => {
        if (
          e.selectedItem?.teamId &&
          e.selectedItem?.teamId.some((g: any) => g === element.id)
        ) {
          filteredTeam.push(element);
        }
      });
      let filteredCrew: any = [];
      this.state.crewItems.forEach((element: any) => {
        if (
          e.selectedItem?.teamId &&
          e.selectedItem?.teamId.some(
            (g: any) =>
              g === element.teamId &&
              element.departmentId === e.selectedItem.departmentId
          )
        ) {
          filteredCrew.push(element);
        }
      });
      let filteredUsers: any = "";
      if (filteredCrew.length === 1) {
        filteredUsers = this.state.alluseritems.filter(
          (c: any) => c.crewId === filteredCrew[0].id
        );
      }
      let layers = e.selectedItem?.gisLayers!;
      let layersTpPass = "";
      if (layers) {
        layersTpPass = layers.layers.join();
      }

      this.setState({
        departmentItems: selectedDeptItem,
        befpicreq: Boolean(e.selectedItem.befpicreq),
        aftpicreq: Boolean(e.selectedItem.aftpicreq),
        riskassessment: Boolean(e.selectedItem.riskassessment),
        tempTeamItems: filteredTeam,
        alltempTeamItems: filteredTeam,
        tempCrewItems: filteredCrew,
        team: filteredTeam && filteredTeam.length === 1 ? filteredTeam[0] : "",
        crewteam:
          filteredCrew && filteredCrew.length === 1 ? filteredCrew[0] : "",
        leadinghand:
          filteredUsers && filteredUsers.length === 1 ? filteredUsers[0] : "",
        useritems:
          filteredUsers && filteredUsers.length > 0
            ? filteredUsers
            : this.state.alluseritems.filter(
              (c: any) =>
                c.departmentId === e.selectedItem.departmentId &&
                c.teamId === e.selectedItem.teamId
            ),
        department:
          selectedDeptItem && selectedDeptItem.length === 1
            ? selectedDeptItem[0]
            : "",
        al: layersTpPass,
      });
    }
    if (fieldName === "department" && e.selectedItem !== null) {
      const { teamsItems } = this.state;
      this.setState({
        WRTTypeItems: [],
        tempCrewItems: [],
        tempTeamItems: [],
        team: "",
        crew: "",
      });
      const filteredTeam = teamsItems.filter(
        (t: any) => t.departmentId === e.selectedItem?.id
      );
      let filteredCrew: any = "";
      if (filteredTeam && filteredTeam.length === 1) {
        filteredCrew = this.state.crewItems.filter(
          (c: any) =>
            c.teamId === filteredTeam[0]?.id &&
            c.departmentId === e.selectedItem?.id
        );
      }
      let filteredUsers: any = "";
      if (filteredCrew.length === 1) {
        filteredUsers = this.state.alluseritems.filter(
          (c: any) => c.crewId === filteredCrew[0].id
        );
      }
      const filteredWRTType = this.state.allWRTTypeItems.filter(
        (c: any) => c.departmentId === e.selectedItem?.id
      );
      this.setState({
        WRTTypeItems: filteredWRTType,
        tempTeamItems: filteredTeam,
        alltempTeamItems: filteredTeam,
        team: filteredTeam && filteredTeam.length === 1 ? filteredTeam[0] : "",
        tempCrewItems: filteredCrew ? filteredCrew : [],
        crewteam:
          filteredCrew && filteredCrew.length === 1 ? filteredCrew[0] : "",
        leadinghand:
          filteredUsers && filteredUsers.length === 1 ? filteredUsers[0] : "",
        useritems:
          filteredUsers && filteredUsers.length > 0
            ? filteredUsers
            : this.state.alluseritems.filter(
              (c: any) =>
                c.departmentId === e.selectedItem.departmentId &&
                c.teamId === e.selectedItem.teamId
            ),
      });
      setTimeout(() => this.setState({ loading: false }), 1000);
    }
    if (fieldName === "team" && e.selectedItem !== null) {
      const { crewItems, department } = this.state;
      this.setState({
        // departmentItems: [],
        tempCrewItems: [],
        crew: "",
      });
      // const selectedDeptItem = this.state.alldepartmentItems.filter((r: any) => r.id === e.selectedItem.departmentId)

      const filteredCrew = crewItems.filter(
        (c: any) =>
          c.teamId === e.selectedItem?.id && c.departmentId === department?.id
      );
      let filteredUsers: any = "";
      if (filteredCrew.length === 1) {
        filteredUsers = this.state.alluseritems.filter(
          (c: any) => c.crewId === filteredCrew[0].id
        );
      }
      this.setState({
        // departmentItems: selectedDeptItem,
        disableCrew: false,
        tempCrewItems: filteredCrew,
        alltempCrewItems: filteredCrew,
        crewteam:
          filteredCrew && filteredCrew.length === 1 ? filteredCrew[0] : "",
        useritems:
          filteredUsers && filteredUsers.length > 0
            ? filteredUsers
            : this.state.alluseritems.filter(
              (c: any) =>
                c.departmentId === e.selectedItem.departmentId &&
                c.teamId === e.selectedItem?.id
            ),
        leadinghand:
          filteredUsers && filteredUsers.length === 1 ? filteredUsers[0] : "",
      });
    }
    if (fieldName === "crewteam" && e.selectedItem !== null) {
      const { alluseritems } = this.state;
      this.setState({
        useritems: [],
        leadinghand: "",
      });
      const filteredUsers = alluseritems.filter(
        (c: any) => c.crewId === e.selectedItem?.id
      );

      this.setState({
        disableleadinghand: false,
        useritems:
          filteredUsers && filteredUsers.length > 0
            ? filteredUsers
            : this.state.alluseritems.filter(
              (c: any) =>
                c.departmentId === e.selectedItem.departmentId &&
                c.teamId === e.selectedItem?.id
            ),
        leadinghand:
          filteredUsers && filteredUsers.length === 1 ? filteredUsers[0] : "",
      });
    }

    this.setState({ [fieldName]: e.selectedItem });
  }
  public _textAreaChanged = (e: any, fieldName: string) => {
    this.setState({ [fieldName]: e.value });
  };
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        hideAlertButton={this.state.hideAlertButton}
        onClose={() => this._closeDialog()}
      />
    );
  }
  public render(): React.ReactElement<any> {
    const componentConfig = {
      showFiletypeIcon: true,
      postUrl: "no-url",
    };
    const djsConfig = {
      autoProcessQueue: false,
      addRemoveLinks: true,
      maxFilesize: 5,
    };

    const eventHandlers = {
      init: function (dz: any) {
        myDropzone = dz;
      },
      addedfile: (file: any) => {
        this.handleEvent(file);
      },
      removedfile: (file: any) => {
        this.deleteFile(file);
      },
    };
    return (
      <div className={"dashboardHome"}>
        {this.state.showAlert && this.alert()}
        {this.state.showPanel && this.renderAssetPanel()}
        <div className={!this.state.showServiceReq ? "SearchDiv" : ""}>
          <div className={"headingDiv"}>
            <span className={"heading"}> Create Work Order </span>
          </div>
        </div>
        <div className={"searchFieldDiv"}>
          <Grid container spacing={3}>
            {this.state.showServiceReq && this.state.loadingFields && (
              <Grid item xs={12} sm={12}>
                <DevExtremeAccordion
                  dataSource={this.state.WRdata}
                  collapsible={true}
                  multiple={false}
                  animationDuration={300}
                  itemTitleRender={this.CustomTitle}
                  itemRender={this.CustomItem}
                  id="accordion-container"
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Paper elevation={6} className={"assetPaper"}>
                <Grid container spacing={1}>
                  <Grid item xs={1} sm={1} className={"AssetIconDiv"}>
                    <EmojiTransportationIcon className={"AssetIcon"} />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    className={"AssetLable"}
                    onClick={() => this.setState({ showPanel: true })}
                  >
                    <p className={"AssetboxTitle"}>Search Asset from List</p>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                elevation={6}
                className={"assetPaper"}
                onClick={this.SearchClicked}
              >
                <Grid container spacing={1}>
                  <Grid item xs={1} sm={1} className={"AssetIconDiv"}>
                    <MapIcon className={"AssetIcon"} />
                  </Grid>
                  <Grid item xs={4} sm={4} className={"AssetLable"}>
                    <p className={"AssetboxTitle"}>Search Asset from Map</p>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1}>
            {this.state.Assets && this.state.Assets.length > 0 && (
              <MuiAccordion style={{ width: '100%' }} defaultExpanded>
                <MuiAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={'classes.heading selectAssets'}>Selected Assets</Typography>
                </MuiAccordionSummary>
                <MuiAccordionDetails>
                  <Grid container spacing={1}>
                    {this.state.Assets.map((asset: any, index: number) => (
                      <Grid item xs={12} sm={12} key={index}>
                        <Paper elevation={12} className={"assetSelectPaper"}>
                          <span className={""}>
                            {asset.erpAssetId ? asset.erpAssetId : asset.AssetID}, {""}
                            {asset.AssetName ? asset.AssetName : asset.name}

                          </span>
                          <span className="floatRight">
                            <DeleteIcon
                              className={"danger"}
                              onClick={() => this.removeAssetNumber(index)}
                            />
                          </span>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </MuiAccordionDetails>
              </MuiAccordion>
            )}
          </Grid>

          <br />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SelectBoxInput
                stylingMode="underlined"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label="Work Order Type*"
                items={this.state.WRTTypeItems}
                selectedItem={this.state.WRTType}
                onSelectionChanged={(e: any) =>
                  this.handleDropdownChange(e, "WRTType")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectBoxInput
                stylingMode="underlined"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label="Department *"
                items={this.state.departmentItems}
                selectedItem={this.state.department}
                onSelectionChanged={(e: any) =>
                  this.handleDropdownChange(e, "department")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectBoxInput
                stylingMode="underlined"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.tempTeamItems}
                selectedItem={this.state.team ? this.state.team : ""}
                label="Teams *"
                onSelectionChanged={(e: any) =>
                  this.handleDropdownChange(e, "team")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectBoxInput
                stylingMode="underlined"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label="Crew"
                items={this.state.tempCrewItems}
                selectedItem={this.state.crewteam ? this.state.crewteam : ""}
                onSelectionChanged={(e: any) =>
                  this.handleDropdownChange(e, "crewteam")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectBoxInput
                stylingMode="underlined"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label="Leading Hand"
                items={this.state.useritems}
                selectedItem={
                  this.state.leadinghand ? this.state.leadinghand : ""
                }
                onSelectionChanged={(e: any) =>
                  this.handleDropdownChange(e, "leadinghand")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectBoxInput
                stylingMode="underlined"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label="Priority "
                items={this.state.priorityItems}
                selectedItem={this.state.priority ? this.state.priority : ""}
                onSelectionChanged={(e: any) =>
                  this.handleDropdownChange(e, "priority")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectBox
                stylingMode="underlined"
                showClearButton={true}
                label="Failure *"
                valueExpr="ID"
                grouped={true}
                displayExpr="Name"
                dataSource={this.state.FailureItems}
                selectedItem={this.state.Failure}
                disabled={this.state.Assets ? false : true}
                onSelectionChanged={(e: any) =>
                  this.handleDropdownChange(e, "Failure")
                }
              />
            </Grid>
            {this.state.CauseItems && (
              <Grid item xs={12} sm={6}>
                <SelectBox
                  stylingMode="underlined"
                  showClearButton={true}
                  label="Cause *"
                  valueExpr="ID"
                  grouped={true}
                  displayExpr="Name"
                  dataSource={this.state.CauseItems}
                  selectedItem={this.state.Cause}
                  defaultValue={this.state.Cause ? this.state.Cause : ""}
                  disabled={this.state.Assets ? false : true}
                  onSelectionChanged={(e: any) =>
                    this.handleDropdownChange(e, "Cause")
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <SelectBoxInput
                stylingMode="underlined"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.RemedyItems}
                selectedItem={this.state.Remedy}
                disabled={this.state.Assets ? false : true}
                label="Remedy *"
                onSelectionChanged={(e: any) =>
                  this.handleDropdownChange(e, "Remedy")
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextBoxInput
                stylingMode="underlined"
                label="Location *"
                value={
                  this.state.location
                    ? this.state.location
                    : this.state.assetlocation
                }
                onValueChanged={(e: any) =>
                  this.handleValueChange(e, "location")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                  <CheckBoxInput
                    value={this.state.befpicreq}
                    onValueChanged={(e: any) =>
                      this.handleValueChange(e, "befpicreq")
                    }
                    text={"Before Photo Required"}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CheckBoxInput
                    value={this.state.aftpicreq}
                    onValueChanged={(e: any) =>
                      this.handleValueChange(e, "aftpicreq")
                    }
                    text={"After Photo Required"}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CheckBoxInput
                    value={this.state.riskassessment}
                    onValueChanged={(e: any) =>
                      this.handleValueChange(e, "riskassessment")
                    }
                    text={"Site Risk Assessment"}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CheckBoxInput
                    value={this.state.isWorkorderOvertime}
                    onValueChanged={(e: any) =>
                      this.handleValueChange(e, "isWorkorderOvertime")
                    }
                    text={"Overtime Work Order"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateBoxInput
                stylingMode="underlined"
                label="Scheduled Start"
                value={
                  this.state.Eststartdate
                    ? new Date(this.state.Eststartdate)
                    : null
                }
                type="datetime"
                displayFormat={"dd/MM/yy hh:mm a"}
                showClearButton={true}
                onValueChanged={(e: any) =>
                  this.handleValueChange(e, "Eststartdate")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateBoxInput
                stylingMode="underlined"
                label="Scheduled Finish"
                value={
                  this.state.Estenddate ? new Date(this.state.Estenddate) : null
                }
                type="datetime"
                displayFormat={"dd/MM/yy hh:mm a"}
                showClearButton={true}
                onValueChanged={(e: any) =>
                  this.handleValueChange(e, "Estenddate")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="Multilinetext">
              <TextArea
                stylingMode="underlined"
                label="Description *"
                value={this.state.description}
                onValueChanged={(e: any) =>
                  this._textAreaChanged(e, "description")
                }
                maxLength={250}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextAreaInput
                label="Comments"
                value={this.state.comment}
                onValueChanged={(e: any) => this._textAreaChanged(e, "comment")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectBoxInput
                stylingMode="underlined"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.InspectionFormItems}
                selectedItem={this.state.InspectionForm}
                label="Inspection Forms"
                onSelectionChanged={(e: any) =>
                  this.handleDropdownChange(e, "InspectionForm")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Label>Upload attachment related to work order</Label>
              <DropzoneComponent
                eventHandlers={eventHandlers}
                djsConfig={djsConfig}
                config={componentConfig}
                className="dz-message icon ion-upload"
                action="Drop files here or click to upload."
              />
              <span>Max no. of file: 5. Max file size: 5Mb</span>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv"}>
                <Button onClick={this.clearFilter} variant="outlined">
                  Clear
                </Button>
                <Button
                  onClick={this.createWorkOrder}
                  variant="outlined"
                  color="primary"
                  className={"button"}
                >
                  Create
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>

        {this.renderAssetMap()}
        <br />
      </div>
    );
  }
}
