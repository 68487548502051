import * as React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import GavelIcon from '@material-ui/icons/Gavel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import global from '../../classes/global';
import utility from '../../classes/utility';
import AlertDialog from '../common/alertDialog';
import AssignDatatable from '../common/DataTable/AssignDatatable';
import { Config } from '../../classes/config';
import { LoadIndicator } from 'devextreme-react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import RejectWR from './RejectWR';
import CreateWO from '../CreateWorkOrder/CreateWO';
import EditWorkRequest from '../EditWorkRequest/EditWorkRequest';
import EditOutlined from '@material-ui/icons/EditOutlined';
import BlockOutlined from '@material-ui/icons/BlockOutlined';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MyMapComponent from '../common/MapComponent/GoogleMapComponent';
import Paper from '@material-ui/core/Paper';
import Geocode from "react-geocode";
import ScrollView from 'devextreme-react/scroll-view';
import { Popup } from 'devextreme-react/popup';
import Spinner from '../common/reusableComponents/Spinner';
import RefreshIcon from '@material-ui/icons/Refresh';
import PlaceOutlined from '@material-ui/icons/PlaceOutlined';
export default class WRDetail extends React.Component<any, any> {
    public historyItems: any[] = [];
    public _workOrdersItems: any[] = [];
    public _attachmentItems: any[] = [];
    public historyColumns: any;
    public _attachmentColumns: any;
    public WorkRequestID: any;
    public workrequestno: any;
    public _workOrdersColumns: any;
    public _assetColumns: any;
    public currentUser: any;
    constructor(props: any) {
        super(props);
        this.historyItems = [];
        this._workOrdersItems = [];
        this._attachmentItems = [];
        this.currentUser = this.props.currentUser;
        this.WorkRequestID = '';
        this.workrequestno = '';
        this.historyColumns =
            [
                {
                    field: 'comments',
                    title: 'Comment',
                    type: 'string',
                    allowHiding: false,
                },
                {
                    field: 'status',
                    title: 'Status',
                    width: 180,
                    type: 'string',
                    allowHiding: false,
                },
                {
                    field: 'createdBy',
                    title: 'Created By',
                    width: 280,
                    type: 'string'
                },
                {
                    field: 'uploadedon',
                    title: 'Created',
                    width: 180,
                    type: 'datetime'
                }
            ];
        this._attachmentColumns =
            [
                {
                    field: 'id',
                    title: 'ID',
                    // width: 160,
                    type: 'string',
                    allowHiding: false
                },
                {
                    field: 'document',
                    title: 'Name',
                    width: 360,
                    type: 'string',
                    link: 'file'
                },

                {
                    field: 'uploadedon',
                    title: 'Created',
                    width: 160,
                    type: 'datetime'
                },
                {
                    field: 'comments',
                    title: 'Comments',
                    // width: 160,
                    type: 'string'
                }
            ];
        this._workOrdersColumns =
            [
                {
                    field: 'Workordernumber',
                    title: 'Work Order#',
                    // width: 160,
                    type: 'string',
                    allowHiding: false,
                    link: 'workorderLink',
                },
                {
                    field: 'Title',
                    title: 'Title',
                    // width: 160,
                    type: 'string'
                },
                {
                    field: 'location',
                    title: 'Location',
                    // width: 160,
                    type: 'string'
                },
                {
                    field: 'status',
                    title: 'Status',
                    // width: 160,
                    type: 'string'
                },

                {
                    field: 'created',
                    title: 'Created',
                    width: 160,
                    type: 'datetime'
                }
            ];
        this._assetColumns = [

            {
                field: 'erpExtAssetId',
                title: 'Asset #',
                // width: 160,
                type: 'string',
                link: 'assetLink',
                allowHiding: false
            },
            {
                field: 'AssetName',
                title: 'Asset Name',
                // width: 280,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'Location',
                title: 'Location',
                // width: 280,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'ServiceStatus',
                title: 'ServiceStatus',
                // width: 280,
                type: 'string',
                allowHiding: false,
            },
        ];


        this.state = {
            historyItems: [],
            workOrdersItems: [],
            attachmentItems: [],
            WOitems: [],
            historyColumns: this.historyColumns,
            attachmentColumns: this._attachmentColumns,
            assetColumns: this._assetColumns,
            workOrdersColumns: this._workOrdersColumns,
            selected: 0,
            assetItems: [],
            selectedTopTab: 0
        };
    }

    public async componentDidMount() {
        let reqId;
        let reqNo;
        if (this.props.createWoMode) {
            reqId = this.props.reqId;
            reqNo = this.props.WRNumber;
        } else {
            let search = this.props.location.search;
            let params = new URLSearchParams(search);
            reqId = params.get('ReqId');
        }
        this.workrequestno = reqNo;
        this.WorkRequestID = reqId;
        await Promise.all([
            this.getWRDetails(),
            this.getWO(),
            this.getWRDocument(),
            this.getWRHistory()
        ]);

    }
    public async loadAssetDetails(AsseticID: string) {
        this.setState({ loadingAssetDetail: true });
        let dataToPassToService: any = {};
        dataToPassToService.assetId = AsseticID;
        try {
            const data: any = await utility.genericPostAPIcall(Config.SearchAssetDetails, dataToPassToService);
            let assetDetailsItems: any[] = [];
            if (data.length > 0) {
                data.forEach((item: any) => {
                    assetDetailsItems.push({
                        Register: item.registerTitle,
                        AssetNumber: item.id,
                        id: item.id,
                        locationId: item.locationId,
                        AssetClass: item.classTitle,
                        AssetID: item.id,
                        AssetName: item.title,
                        erpAssetId: item.erpAssetId,
                        erpExtAssetId: item.erpExtAssetId,
                        ServiceStatus: item.assetStatus,
                        Description: item.description,
                        Location: item.locationDescription,
                        Category: item.assetCategoryTitle,
                        erpExtSystemName: item.erpExtSystemName,
                        maintenanceAssetType: item.maintenanceAssetType,
                        accntSystemId: item.accntSystemId,
                        workGroupIds: item.workGroupIds
                    });
                });

                this.setState({
                    assetItems: assetDetailsItems,
                    loadingAssetDetail: false
                });
            } else {
                this.setState({
                    assetdetails: [],
                    loadingAssetDetail: false
                });
            }
        } catch (err) {
            console.log(err);
            this.setState({ assetdetails: [], loadingAssetDetail: false });
        }

    }
    public async getWRDetails() {
        this.setState({
            loading: true
        });
        let dataToPassToService: any = {};
        dataToPassToService.workRequestId = this.WorkRequestID;
        try {
            const items: any = await utility.genericPostAPIcall(Config.WRlistname, dataToPassToService);
            items.forEach(async (element: any) => {
                if (element.locationLatLong === null) {
                    this.getLatlong(element.location.trim());
                } else {
                    this.setState({
                        marker: { lng: element.locationLatLong ? element.locationLatLong.x : 0, lat: element.locationLatLong ? element.locationLatLong.y : 0 }
                    });
                }
                if (element.assetId) {
                    await this.loadAssetDetails(element.assetId);
                }
                this.setState({
                    comments: element.comments,
                    workrequestdetail: element,
                    loading: false
                });
            })
        } catch (err) {
            console.log(err);
        }

    }
    public async getWO() {
        let dataToPassToService: any = {};
        if (this.WorkRequestID) {
            dataToPassToService.workRequestId = this.WorkRequestID;
        }
        let searchWOAPi = `${Config.WOSearchAPI}`
        try {
            const data: any = await utility.genericPostAPIcall(searchWOAPi, dataToPassToService);

            let _WOitems: any = [];
            if (data.length === 0) {
                if (_WOitems.length <= 0) {
                    this.setState({
                        alertMessage: 'No results found for this criteria',
                        showAlert: true
                    });
                }
                this.setState({ WOitems: _WOitems, loading: false });
                return;
            }
            else {
                data.forEach((item: any) => {
                    _WOitems.push({
                        id: item.id,
                        woid: item.id,
                        Workordernumber: item.id,
                        Title: item.title,
                        created: item.created,
                        updatedOn: item.updated,
                        status: item.status,
                        location: item.workorderLocation ? item.workorderLocation : ''
                    });
                });

            }
            _WOitems = utility._sortItems(_WOitems, 'updatedOn', true);
            this.setState({ WOitems: _WOitems, loading: false });
        } catch (err) {
            console.log(err);
        }
    }
    public viewdocument(a: any) {
        let item = a;
        let dataToPassToService: any = {};
        if (!item.document) {
            this.setState({
                alertMessage: 'Unable to view this document. \n File URL or File name does exist',
                showAlert: true
            });
            return;
        }


        dataToPassToService.fileName = item.document;
        dataToPassToService.fileUrl = item.fileURL;
        if ((item.fileURL).includes(global.isAzurefile)) {
            utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {
                window.open(doc, "_blank");
            });
        }
        else {
            let fileurl = item.fileURL.toLowerCase();

            var pattern = /.*href="(.*)".*/;
            var href = fileurl.replace(pattern, '$1');

            let docurl = "<a href=" + href + "></a>"
            window.open(docurl, "_blank");
        }

        dataToPassToService.fileName = item.document;
        dataToPassToService.fileUrl = item.fileURL;


    }

    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this._onCloseDialog()}
            />
        );
    }
    public _onCloseDialog() {
        this.getWRDetails();
        this.setState({ alertMessage: '', showAlert: false });
        if (this.state.goBack) {
            utility.goBack();
        }
    }
    public _onClosePanel() {
        this.getWRDetails();
        this.setState({ showPanel: false, showEditPanel: false });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    public handleSelect = (event: any, newValue: any) => {
        this.setState({ selected: newValue });
    }
    public getWorkOrdersForDepartment() {
        let workOrdersFromList: any = [];
        utility.genericGetDataByIdAPICall(Config.WRList, this.WorkRequestID).then((results: any) => {
            if (workOrdersFromList.length > 0) {
                workOrdersFromList = results;
                this._workOrdersItems = results;
                this._workOrdersItems.forEach(element => {
                    element.id = +element.id;
                });
            }
            else {
                this._workOrdersItems = [];
            }
            this.setState({
                workOrdersCount: this._workOrdersItems.length,
                workOrdersItems: this._workOrdersItems,
                workOrdersColumns: this._workOrdersColumns
            });
        }, (err) => {
            console.log(err);
        });
    }
    public async getWRHistory() {
        this.setState({ documentloading: true })
        let dataToPassToService: any = {};
        dataToPassToService.workRequestId = this.WorkRequestID;
        try {
            const WRhistory: any = await utility.genericPostAPIcall(Config.SearchWRHistory, dataToPassToService);
            this.historyItems = [];
            if (WRhistory.length !== undefined && WRhistory.length > 0) {
                WRhistory.forEach((item: any) => {
                    this.historyItems.push({
                        status: item.statusTitle ? item.statusTitle : '',
                        uploadedon: item.created,
                        createdBy: item.createdBy,
                        id: item.id ? item.id : ' ',
                        comments: item.comments ? item.comments : ''
                    });
                });

            }
            this.historyItems = utility._sortItems(this.historyItems, 'uploadedon', true);
            this.setState({
                historyItems: this.historyItems,
                callAfterInit: true,
                WRhistoryloading: false
            });
        }
        catch (err) {
            console.log(err);
        }
    }
    public async getWRDocument() {
        this.setState({ documentloading: true })
        try {
            const WRdoc: any = await utility.genericGetDataByIdAPICall(Config.WRDocuments, this.WorkRequestID);
            this._attachmentItems = [];
            if (WRdoc.length !== null || WRdoc.length !== 0) {
                WRdoc.forEach((item: any) => {
                    if (item.url != null) {
                        this._attachmentItems.push({
                            document: item.name ? item.name : '',
                            uploadedon: item.created,
                            id: item.id ? item.id : ' ',
                            fileURL: item.url ? item.url : '',
                            comments: item.comments ? item.comments : ''
                        });
                    }
                });
                this._attachmentItems = utility._sortItems(this._attachmentItems, 'uploadedon', true);
                this.setState({
                    DocPhotosCount: WRdoc.length,
                    attachmentItems: this._attachmentItems,
                    callAfterInit: true,
                    documentloading: false
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
    public message(message: any) {
        //console.log(message);
        this.setState({
            alertMessage: message + " successfully",
            showAlert: true,
            hideAlertButton: false,
            showCreateWOModal: false
        });
        setTimeout(this.getWRDetails.bind(this), 3000);
    }

    public renderRejectWR() {
        return (
            <div>
                <Dialog
                    open={this.state.showPanel}
                    onClose={() => this.setState({ showPanel: false })}
                    maxWidth={'sm'}
                    aria-labelledby="max-width-dialog-title"
                    fullWidth={true}
                >
                    <DialogContent dividers={true}>
                        <IconButton aria-label="close" onClick={() => this.setState({ showPanel: false })} className="closeIcon">
                            <CloseIcon />
                        </IconButton>
                        <RejectWR
                            WRId={this.WorkRequestID}
                            currentUser={this.currentUser}
                            showModal={this.state.showPanel}
                            callAfterInit={this.state.callAfterInit}
                            aadHttpClient={this.props.aadHttpClient}
                            onClose={() => this._onClosePanel()}
                            onClick={() => this.getWRDetails()}
                            stopCommsAfterInit={() => this.stopCommsAfterInit()}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }

    public renderEditWR() {
        return (
            <div>
                <Dialog
                    open={this.state.showEditPanel}
                    onClose={() => this.setState({ showEditPanel: false })}
                    maxWidth={'sm'}
                    aria-labelledby="max-width-dialog-title"
                    fullWidth={true}
                >
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <IconButton aria-label="close" onClick={() => this.setState({ showEditPanel: false })} className="closeIcon">
                                <CloseIcon />
                            </IconButton>
                            <EditWorkRequest
                                WRId={this.WorkRequestID}
                                WRNo={this.workrequestno}
                                currentUser={this.currentUser}
                                showModal={this.state.showEditPanel}
                                callAfterInit={this.state.callAfterEWRInit}
                                aadHttpClient={this.props.aadHttpClient}
                                onClose={() => this._onClosePanel()}
                                onClick={() => this.getWRDetails()}
                                stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                details={this.state.workrequestdetail}
                            />
                        </ScrollView>
                    </DialogContent>

                </Dialog>
            </div >
        );
    }
    public renderCreateWOModal() {
        return (
            <Popup
                width={'80vw'}
                maxHeight={'85vh'}
                minHeight={200}
                height={'auto'}
                showTitle={true}
                showCloseButton={true}
                dragEnabled={true}
                hideOnOutsideClick={false}
                visible={this.state.showCreateWOModal}
                closeOnOutsideClick={true}
                onVisibleChange={() => this.setState({ showCreateWOModal: false })}

            >
                <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                    <CreateWO
                        showServiceReq={true}
                        currentUser={this.currentUser}
                        Assets={this.state.assetItems}
                        data={[this.state.workrequestdetail]}
                        workrequestTypeId={this.state.workrequestdetail.workRequestTypeId}
                        aadHttpClient={this.props.aadHttpClient}
                        callAfterInitServReq={this.state.callAfterInitServReq}
                        stopCommsAfterInitServReq={() => this.setState({ callAfterInitServReq: false })}
                        onClick={() => setTimeout(this.getWorkOrdersForDepartment.bind(this), 1000)}
                        onClose={() => this.setState({ showCreateWOModal: false })}
                        message={(e: any) => this.message(e)}
                    />
                </ScrollView>

            </Popup>
        );
    }
    public handleSelectTopTab = (event: any, newValue: any) => {
        this.setState({ selectedTopTab: newValue });
    }
    public getLatlong(place: any) {
        let address = place.toLowerCase();
        if (address.includes('street')) {
            address = address.replace("street", "st");
        }

        Geocode.fromAddress(address).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;

                if (lat && lng) {
                    let marker = { lat: lat, lng: lng };
                    this.setState({ marker: marker });
                }
            },
            (error) => {
                console.error(error);
            }
        );

    }
    public rendermapModal() {
        return (
            <Popup
                width={'80vw'}
                maxHeight={'85vh'}
                minHeight={200}
                height={'auto'}
                showTitle={true}
                showCloseButton={true}
                dragEnabled={true}
                hideOnOutsideClick={false}
                visible={this.state.showMapPanel}
                closeOnOutsideClick={true}
                onVisibleChange={() => this.setState({ showMapPanel: false })}
            >
                <MyMapComponent
                    singleMarkers={this.state.marker}
                    center={this.state.marker}
                    useStyle={this.state.useStyle}
                    iconColor={'33cc33'}
                    zoom={18}
                    height="35vh"
                    showMarker={true}

                />

            </Popup>
        );
    }
    public render(): React.ReactElement<any> {
        const {
            historyItems, historyColumns,
            WOitems, workOrdersColumns, assetItems, assetColumns,
            attachmentItems, attachmentColumns, comments
        } = this.state;
        return (
            <div>
                {this.state.showCreateWOModal && this.renderCreateWOModal()}
                <div>{this.state.showMapPanel && this.rendermapModal()}</div>
                {this.state.showPanel && this.renderRejectWR()}
                {this.state.showEditPanel && this.renderEditWR()}
                {(this.state.workrequestdetail) && (
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={6}>
                            <div className={"SearchDiv headingDiv"}>
                                <span className={"heading"}>
                                    Work Request
                                    {this.state.workrequestdetail && (
                                        <>
                                            <span>
                                                {' '}
                                                - {this.state.workrequestdetail.RequestTypeDesc} ({this.state.workrequestdetail.id})
                                            </span>
                                        </>
                                    )}
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <div className='icon-buttons-list'>
                                <IconButton aria-label="view" color="primary" onClick={() => this.getWRDetails()} >
                                    <RefreshIcon titleAccess='Refresh the data' className={'iconcolor'} />
                                </IconButton>
                                <IconButton onClick={() => this.setState({ showCreateWOModal: true, callAfterInitServReq: true })} disabled={this.state.workrequestdetail.workRequestStatusTitle !== "New" ? true : false}
                                >
                                    <GavelIcon titleAccess='Create Work Order' className={'iconcolor'} />
                                </IconButton>
                                <IconButton
                                    onClick={() => this.setState({ showEditPanel: true, callAfterEWRInit: true })} disabled={this.state.workrequestdetail.workRequestStatusTitle !== "New" ? true : false}
                                >
                                    <EditOutlined titleAccess='Edit Work Request' className={'iconcolor'} />
                                </IconButton>
                                <IconButton
                                    onClick={() => this.setState({ showPanel: true, callAfterInit: true })} disabled={this.state.workrequestdetail.workRequestStatusTitle !== "New" ? true : false}>
                                    <BlockOutlined titleAccess='Reject Work Request' className={'iconcolor'} />
                                </IconButton>
                                <IconButton onClick={() => this.setState({ showMapPanel: true })}>
                                    <PlaceOutlined titleAccess='Show Map' className={'iconcolor'} />
                                </IconButton>
                            </div>
                        </Grid>
                    </Grid>
                )}

                {
                    (!this.state.workrequestdetail) && (
                        <div>
                            <Spinner size='large' label={'Loading details'} />
                            <br />
                        </div>
                    )
                }
                {
                    (this.state.workrequestdetail) && (
                        <div>

                            <div className={"detailFieldDiv"}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={7}>
                                        <div className="serReqTitle">Work Request Details</div><br />
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>Assetic Req #</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>
                                                {this.state.workrequestdetail.erpWorkRequestNumber ? <a href={global.AsseticWRLink + this.state.workrequestdetail.erpWorkRequestNumber} rel="noreferrer" className="WOlink" target="_blank">{this.state.workrequestdetail.erpWorkRequestNumber}</a> : ''}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>Assetic ID</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}><a href={global.AsseticAssetURL + this.state.workrequestdetail.erpAssetId} className="WOlink" rel="noreferrer" target="_blank">{this.state.workrequestdetail.erpAssetId ? this.state.workrequestdetail.erpAssetId : ''}</a></Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>External Asset ID:</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workrequestdetail.erpExtAssetId}</Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>Request Type</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workrequestdetail.workRequestTypeTitle}</Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>Description</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workrequestdetail.workRequestDescription}</Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>WO Work Req #</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>
                                                {this.state.workrequestdetail.id}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>Requested By</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workrequestdetail.createdByFullName}</Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>Created Date</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>
                                                {this.state.workrequestdetail.created ?
                                                    moment(this.state.workrequestdetail.created).format("DD/MM/YY h:mm a") : null}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>Resolution Due Date</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>
                                                {this.state.workrequestdetail.created ?
                                                    moment(this.state.workrequestdetail.resolutionDueDate).format("DD/MM/YY h:mm a") : null}
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>Status</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workrequestdetail.workRequestStatusTitle}</Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>Location</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workrequestdetail.location}</Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>Reactive Inspector:</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workrequestdetail.inspectedBy ? this.state.workrequestdetail.inspectedBy : ''}</Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>Reference Number</Grid>
                                            <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workrequestdetail.referenceNumber}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5}>
                                        <Paper square className='mapwidth'>
                                            <Tabs
                                                value={this.state.selectedTopTab}
                                                onChange={this.handleSelectTopTab}
                                                indicatorColor="primary"
                                                // textColor="primary"
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="scrollable auto tabs example"
                                            >
                                                <Tab label={<span className='tabHeading'>Map</span>} {...a11yPropsTop(0)} />
                                            </Tabs>
                                        </Paper>

                                        <TabPanel value={this.state.selectedTopTab} index={0}>
                                            <div className='mapwidth'>
                                                {this.state.marker && this.state.marker.lat !== 0 ?
                                                    <MyMapComponent
                                                        singleMarkers={this.state.marker}
                                                        center={this.state.marker}
                                                        useStyle={this.state.useStyle}
                                                        iconColor={'33cc33'}
                                                        zoom={18}
                                                        height="35vh"
                                                        showMarker={true}

                                                    /> :
                                                    <h3>No location data</h3>}
                                            </div>
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    )
                }
                <br />
                <br />
                <br />
                {
                    (this.state.workrequestdetail) && (
                        <div className={'tabsDiv'}>
                            <AppBar position="static">
                                <Tabs
                                    value={this.state.selected}
                                    onChange={this.handleSelect}
                                    indicatorColor="primary"
                                    // textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    <Tab className='tabHeading' label={'History(' + historyItems.length + ')'} {...a11yProps(0)} />
                                    <Tab className='tabHeading' label={'Documents/Photos(' + attachmentItems.length + ')'}  {...a11yProps(1)} />
                                    <Tab className='tabHeading' label={'Work order(' + WOitems.length + ')'}  {...a11yProps(2)} />
                                    <Tab className='tabHeading' label={'Assets(' + assetItems.length + ')'}   {...a11yProps(3)} />
                                    <Tab className='tabHeading' label={'Comments(' + (comments ? 1 : 0) + ')'}  {...a11yProps(3)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.selected} index={0}>

                                {!this.state.loading ?
                                    <>
                                        {(historyItems && historyItems.length > 0) && (
                                            <div className="tabStripDiv">
                                                <AssignDatatable
                                                    style={{ width: '100%' }}
                                                    fileName={`History - ${this.state.RequestNumber}`}
                                                    filteralbe={false}
                                                    groupable={false}
                                                    rows={historyItems}
                                                    columns={historyColumns}
                                                    selectionMode={false}
                                                    showHideColumnMenu={false}
                                                    callAfterInit={this.state.callAfterInit}
                                                    stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                                    loading={this.state.loading}
                                                    allowExportSelectedData={false}
                                                    showNewBtn={false}
                                                    hideExport={true}
                                                    hideSearchPanel={true}
                                                    showCommandBtn={false}
                                                    showViewDetailBtn={false}
                                                    wordWrapEnabled={true}
                                                />
                                            </div>
                                        )}
                                    </>
                                    :
                                    // <CircularProgress className="loader" />
                                    <LoadIndicator id="large-indicator" height={60} width={60} className="loader" />
                                }
                            </TabPanel>
                            <TabPanel value={this.state.selected} index={1}>
                                {(attachmentItems && attachmentItems.length > 0) && (
                                    <div className="tabStripDiv">
                                        <AssignDatatable
                                            style={{ width: '100%' }}
                                            fileName={`Documents - ${this.state.RequestNumber}`}
                                            filteralbe={false}
                                            groupable={false}
                                            showToolBar={false}
                                            rows={attachmentItems}
                                            columns={attachmentColumns}
                                            selectionMode={false}
                                            showHideColumnMenu={false}
                                            callAfterInit={this.state.callAfterInit}
                                            stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                            loading={this.state.loading}
                                            allowExportSelectedData={false}
                                            hideExport={true}
                                            showNewBtn={false}
                                            hideSearchPanel={true}
                                            viewFile={(e: any) => this.viewdocument(e)}
                                            showCommandBtn={false}
                                            showViewDetailBtn={false}
                                        />
                                    </div>
                                )}
                            </TabPanel>

                            <TabPanel value={this.state.selected} index={2}>
                                {(WOitems && WOitems.length > 0) && (
                                    <div className="tabStripDiv">
                                        <AssignDatatable
                                            style={{ width: '100%' }}
                                            filteralbe={true}
                                            groupable={true}
                                            rows={WOitems}
                                            columns={workOrdersColumns}
                                            selectionMode={false}
                                            showHideColumnMenu={false}
                                            callAfterInit={this.state.callAfterInit}
                                            stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                            loading={this.state.loading}
                                            allowExportSelectedData={false}
                                            hideExport={true}
                                            showNewBtn={false}
                                            hideSearchPanel={true}
                                            showCommandBtn={false}
                                            showViewDetailBtn={false}
                                        />
                                    </div>
                                )}
                            </TabPanel>
                            <TabPanel value={this.state.selected} index={3}>
                                {(assetItems && assetItems.length > 0) && (
                                    <div className="tabStripDiv">
                                        <AssignDatatable
                                            style={{ width: '100%' }}
                                            fileName={`Asets - ${this.state.RequestNumber}`}
                                            filteralbe={false}
                                            groupable={false}
                                            showToolBar={false}
                                            rows={assetItems}
                                            columns={assetColumns}
                                            selectionMode={false}
                                            showHideColumnMenu={false}
                                            callAfterInit={this.state.callAfterInit}
                                            stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                            loading={this.state.loading}
                                            allowExportSelectedData={false}
                                            hideExport={true}
                                            showNewBtn={false}
                                            hideSearchPanel={true}
                                            viewFile={(e: any) => this.viewdocument(e)}
                                            showCommandBtn={false}
                                            showViewDetailBtn={false}
                                        />
                                    </div>
                                )}
                            </TabPanel>
                            <TabPanel value={this.state.selected} index={4}>
                                <div className="m-20">
                                    {comments && comments ?
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12}>
                                                        <div dangerouslySetInnerHTML={{ __html: `${comments.replace(/\n/g, "<br />")}` }}></div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        :
                                        <p className={'alignCenter'}>No comment found</p>}
                                </div>
                            </TabPanel>
                        </div>


                    )
                }

            </div >

        );
    }


}

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function a11yPropsTop(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
