import * as React from "react";
import utility from "../../../classes/utility";
import { Config } from "../../../classes/config";

import DataTable from "../../common/DataTable/DataTable";
import AlertDialog from "../../common/alertDialog";
import Spinner from "../../common/reusableComponents/Spinner";
import TextBoxInput from "../../common/reusableComponents/TextBox";
import DateBoxInput from "../../common/reusableComponents/DateBox";
import NumberBoxInput from "../../common/reusableComponents/NumberBox";
import CheckBoxInput from "../../common/reusableComponents/CheckBox";
import SelectBoxInput from "../../common/reusableComponents/SelectBox";
import global from "../../../classes/global";

import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
//import { DataGrid } from "devextreme-react";

let _items: any = [];

export default class LoginDetails extends React.Component<any, any> {
  public currentUser: any;
  public columns: any;
  public departmentItems: any = [];

  constructor(props: any) {
    super(props);
    this.currentUser = this.props.currentUser;
    if (this.props.role !== global.worxOnline_Admin) {
      window.open("#/AccessDenied", "_self");
    }
    this.departmentItems = [];
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.getEmpNames = this.getEmpNames.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    const moment = require("moment");
    this.columns = [
      {
        field: "id",
        title: "ID",
        width: 80,
        type: "number",
        allowHiding: false,

      },
      {
        field: "userFullName",
        title: "User Name",
        width: 250,
        type: "string",
        allowHiding: false,
      },
      {
        field: "loggedInFrom",
        title: "Logged in From",
        width: 160,
        type: "string",
        allowHiding: true,
      },
      {
        field: "loggedInTime",
        title: "Logged In Time",
        width: 160,
        type: "datetime",
        allowHiding: true,
      },
      {
        field: "loggedOutTime",
        title: "Logged Out Time",
        width: 160,
        type: "datetime",
        allowHiding: true,
      },
      {
        field: "device",
        title: "Device",
        width: 160,
        type: "string",
        allowHiding: true,
      },
      {
        field: "department",
        title: "Department",
        width: 160,
        type: "string",
        allowHiding: true,
        showColumnCount: true,
      },
      {
        field: "updatedBy",
        title: "Updated By",
        width: 160,
        type: "string",
        visibleColumn: false,
        allowHiding: true,
      },
      {
        field: "updated",
        title: "Updated",
        width: 160,
        type: "datetime",
        visibleColumn: false,
        allowHiding: true,
        sortOrder: "desc",
      },
    ];
    this.state = {
      items: _items,
      columns: this.columns,
      showAllLoginData: false,
      employeeId: "",
      dateFrom: new Date(),
      dateTo: "",
      loading: false,
      totalResults: 100,
      department: (this.props.role === global.worxOnline_Contractor || this.props.role === global.worxOnline_ContractorAdmin) ? this.props.Department : '',
    };
  }

  public async componentDidMount() {
    let tempstate = localStorage.getItem('loginState');
    await Promise.all([
      this.getEmpNames(),
      this._getDepartmentData(),
    ]);
    if (tempstate) {
      // this.state = JSON.parse(tempstate)
      let allState = JSON.parse(tempstate);

      this.setState({
        dateFrom: allState.dateFrom ? allState.dateFrom : new Date(),
        dateTo: allState.dateTo ? allState.dateTo : '',
        empName: allState.empName ? allState.empName : '',
        department: allState.department ? allState.department : '',
      })
    }
  }

  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === "Enter") {
      this.getUserData();
    }
  };

  private _getDepartmentData() {
    utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`).then((data: any) => {
      this.departmentItems = [];
      if (data && data.length > 0) {
        this.departmentItems = data
          .filter((r: any) => r.isActive === true)
          .map((r: any) => ({
            id: r.id, text: r.title
          }));
      }
      this.departmentItems = utility._sortItems(this.departmentItems, 'text', false);
      this.setState({ departmentItems: this.departmentItems, alldepartmentItems: this.departmentItems });
    }, (err) => {
      console.log(err);
    });
  }

  private handleValueChange(newValue: any, fieldName: string): void {
    this.setState({ [fieldName]: newValue.value });
  }

  public handleDropdownChange(e: any, fieldName: string) {
    if (fieldName === 'department') {
      if (e.selectedItem !== null) {
        let tempemployeenameItems = this.state.allEmpNames.filter((f: any) => f.departmentId === e.selectedItem.id);
        this.setState({
          empNames: tempemployeenameItems,
        });
      } else {
        this.setState({
          empNames: this.state.allEmpNames,
        });
      }
    }
    this.setState({ [fieldName]: e.selectedItem });
  }

  private getEmpNames() {
    let dataToPassToService: any = {};
    this.setState({
      loading: true,
    });
    // console.log("dataToPassToService", dataToPassToService);
    utility
      .genericGetAPICallForList(
        Config.SearchEmployeeApi,
        "POST",
        dataToPassToService
      )
      .then(
        (data: any) => {
          _items = [];
          //console.log(data);

          for (let r of data) {
            _items.push({
              id: r.id,
              text: r.fullName,
              departmentId: r.departmentId,
              active: r.isActive ? true : false,
            });
          }
          //Show users who are active and do not have 'Dafault' as their department
          const filteredItems = _items.filter((item: any) => (item.active === true && item.departmentId !== 11));
          _items = utility._sortItems(filteredItems, "text", false);

          this.setState({
            empNames: filteredItems,
            allEmpNames: filteredItems,
            loading: false
          });
        },
        (err) => {
          console.log(err);
          this.setState({ loading: false });
        }
      );
  }

  public getUserData() {
    this.setState({ loading: true });
    let dataToPassToService: any = {};

    if (this.state.empName) {
      dataToPassToService.userId = this.state.empName.id;
    }

    if (this.state.department) {
      dataToPassToService.departmentId = this.state.department.id;
    }

    if (this.state.dateTo && this.state.dateFrom) {
      const dcreatedfrom = moment(this.state.dateFrom);
      const dcreatedTo = moment(this.state.dateTo);

      if (dcreatedTo.isBefore(dcreatedfrom, 'day')) {
        this.setState({
          alertMessage: 'Date To should be greater than Date From',
          showAlert: true,
          loading: false
        });
        return;
      }
    }

    if (this.state.dateFrom || this.state.dateTo) {
      if (this.state.dateFrom) {
        const startOfDay = moment(this.state.dateFrom).startOf('day');
        const startOfDayUTC = startOfDay.utc();
        dataToPassToService.from = startOfDayUTC.toISOString();
      }
      //   if (this.state.dateFrom) {
      //     // Parse the date with Moment Timezone and set it to the start of the day (midnight) in the local timezone
      //     const userTimezone = moment.tz.guess();
      //     const startOfDayInUserTimezone = moment.tz(this.state.dateFrom, userTimezone).startOf('day');
      //     dataToPassToService.from = startOfDayInUserTimezone.toISOString();
      // }
      if (this.state.dateTo) {
        const endOfDay = moment(this.state.dateTo).endOf('day');
        const endOfDayUTC = endOfDay.utc();
        dataToPassToService.to = endOfDayUTC.toISOString();
      }
    }

    let pageNumber = 1;
    let pageSize = this.state.totalResults ? this.state.totalResults : 100;
    let searchUserloginAPI = `${Config.searchUserlogin}?pageNumber=${pageNumber}&pageSize=${pageSize}&showAll=${this.state.showAllLoginData}`;

    utility
      .genericGetAPICallForList(searchUserloginAPI, "POST", dataToPassToService)
      .then(
        (data: any) => {
          _items = [];
          if (data.length === null || data.length === 0) {
            this.setState({
              alertMessage: "No results found for this criteria",
              showAlert: true,
            });
            this.setState({ items: _items, loading: false });
            return;
          } else {
            for (let r of data) {
              let loginTime = null;
              let logoutTime = null;
              const userTimezone = moment.tz.guess();
              // if (r.loggedInTime) {
              //   const parsedTime = moment.utc(r.loggedInTime);
              //   const localTime = parsedTime.local().format("HH:mm:ss");
              //   loginTime = parsedTime.format("DD/MM/YYYY") + " " + localTime;
              // }
              if (r.loggedInTime) {
                // Convert the loggedInTime from UTC to the user's local timezone
                loginTime = moment.utc(r.loggedInTime).tz(userTimezone);
              }
              if (r.loggedOutTime) {
                // Convert the loggedOutTime from UTC to the user's local timezone
                logoutTime = moment.utc(r.loggedOutTime).tz(userTimezone);
              }
              _items.push({
                key: r.id,
                id: r.id,
                loggedInFrom: r.loggedInFrom,
                loggedInTime: loginTime,
                loggedOutTime: logoutTime,
                userFullName: r.userFullName,
                device: r.device,
                updatedBy: r.updatedBy,
                updatedOn: r.updated ? r.updated : null,
                createdBy: r.createdBy,
                createdOn: r.created ? r.created : null,
                department: r.departmentTitle,
              });
            }
            _items = utility._sortItems(_items, "id", true);
          }

          this.setState({
            items: _items,
            callAfterInit: true,
            loading: false,
            preItems: _items,
          });

          var stateData = { ...this.state };
          localStorage.setItem('loginState', JSON.stringify(stateData));
        },
        (err) => {
          console.log(err);
          this.setState({ loading: false });
        }
      );
  }

  public clearFilter() {
    this.setState({
      items: [],
      empName: "",
      dateFrom: new Date(),
      dateTo: "",
      department: "",
      loading: false,
      totalResults: 100,
      showAllLoginData: false,
    });
    localStorage.setItem('loginState', '');
    let datagrid = localStorage.getItem('datagridFilterValue');
    if (datagrid) {
      localStorage.removeItem('datagridFilterValue');
    }
  }

  public setSelection = (id: any) => {
    this.setState({
      selectedRow: id,
    });
  };

  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  };

  public render(): React.ReactElement<any> {
    return (
      <React.Fragment>
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={"headingDiv"}>
            <span className={"heading"}> Login Details </span>
          </div>
        </div>
        <div className="searchFieldDiv">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <SelectBoxInput
                stylingMode='underlined'
                label="Department"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.departmentItems}
                selectedItem={this.state.department}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <SelectBoxInput
                stylingMode="underlined"
                label="Employee Name"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.empNames}
                selectedItem={this.state.empName}
                onSelectionChanged={(e: any) =>
                  this.handleDropdownChange(e, "empName")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <DateBoxInput
                stylingMode="underlined"
                label={"Date From"}
                value={
                  this.state.dateFrom ? new Date(this.state.dateFrom) : null
                }
                type="date"
                displayFormat={"dd/MM/yyyy"}
                placeholder={"DD/MM/YYYY"}
                showClearButton={true}
                onValueChanged={(e: any) =>
                  this.handleValueChange(e, "dateFrom")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <DateBoxInput
                stylingMode="underlined"
                label={"Date To"}
                value={this.state.dateTo ? new Date(this.state.dateTo) : null}
                type="date"
                displayFormat={"dd/MM/yyyy"}
                placeholder={"DD/MM/YYYY"}
                showClearButton={true}
                onValueChanged={(e: any) => this.handleValueChange(e, "dateTo")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv displayFlex"}>
                {this.state.totalRecords && (
                  <div className="totalRecordsDiv">
                    Total records available for this search is{" "}
                    {this.state.totalRecords}
                  </div>
                )}
                <div>
                  <NumberBoxInput
                    stylingMode="underlined"
                    label="Total Results to Display"
                    min={1}
                    max={100000000}
                    value={this.state.totalResults}
                    onValueChanged={(e: any) =>
                      this.handleValueChange(e, "totalResults")
                    }
                  />
                </div>
                <div>
                  <Button
                    onClick={this.clearFilter}
                    variant="outlined"
                    className={"clearButton"}
                  >
                    Clear
                  </Button>
                  <Button
                    onClick={this.getUserData}
                    variant="outlined"
                    color="primary"
                    className={"button"}
                  >
                    Search
                  </Button>

                  <CheckBoxInput
                    value={this.state.showAllLoginData}
                    onValueChanged={(e: any) =>
                      this.handleValueChange(e, "showAllLoginData")
                    }
                    text={"Show All"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          {!this.state.loading ? (
            <div className={"searchFieldDiv"}>
              {this.state.items && this.state.items.length > 0 && (
                <DataTable
                  style={{ width: "100%" }}
                  filteralbe={true}
                  groupable={true}
                  rows={this.state.items}
                  columns={this.state.columns}
                  fileName={"LoginDetails"}
                  columnChooser={"LoginDetailsColumns"}
                  selectionMode={false}
                  showHideColumnMenu={true}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showCommandBtn={false}
                  showNewBtn={false}
                  hideNewBtn={
                    this.state.showModal ||
                    this.state.showModal ||
                    this.props.hideNewBtn
                  }
                  showViewDetailBtn={false}
                  showColumnSummary={true}
                />
              )}
            </div>
          ) : (
            <Spinner size="large" label="Loading.." className="loader" />
          )}
        </div>
      </React.Fragment>
    );
  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        hideAlertButton={this.state.hideAlertButton}
        onClose={() => this.setState({ alertMessage: "", showAlert: false })}
      />
    );
  }
}
