import * as React from 'react';
import utility from '../../../classes/utility';
import ClearStorageService from './../../../ClearStorageService';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import capitalize from '@material-ui/core/utils/capitalize';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TagBox } from 'devextreme-react/tag-box';
import NumberBoxInput from "../../common/reusableComponents/NumberBox";
import TextAreaInput from '../../common/reusableComponents/TextArea';
export default class AddEditCrew extends React.Component<any, any> {
    ClearStorageService = new ClearStorageService();
    public teamsItems: any = [];

    constructor(props: any) {
        super(props);
        this.AddEditContractor = this.AddEditContractor.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.state = {
            teams: [],
            loading: false
        };
    }
    public async didMount() {
        await this._getworkTypeDataAsync();
        await this._getTeamDataAsync();
        await this._getUserData();
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                hideAlertButton: false,
                mode: this.props.mode,
                isActive: true
            })
        }

    }

    public populateValue(details: any, mode?: any) {
        this.setState({
            id: this.props.details.id,
            name: this.props.details.name,
            email: this.props.details.email,
            pin: this.props.details.pin,
            businessName: this.props.details.businessName,
            phone: this.props.details.phone,
            councilPhone: this.props.details.councilContactPhone,
            contactPersonEmail: this.props.details.councilContactEmail,
            isActive: this.props.details.isActive,
            hideAlertButton: false,
            loading: false,
            mode: mode
        });
    }
    private async _getworkTypeDataAsync(departmentId?: any) {
        let dataToPassToService: any = {};
        try {
            let data: any = await utility.genericPostAPIcall(Config.SearchActivitiesApi, dataToPassToService);
            let worktypeItems: any = [];

            for (let r of data) {
                worktypeItems.push({
                    id: r.id,
                    text: r.title,
                    befpicreqvalue: r.photoRequiredBefore,
                    aftpicreqvalue: r.photoRequiredAfter,
                    riskassessment: r.riskAssessmentRequired,
                    teamIds: r.teamIds,
                    Teams: r.teams,
                    DepartmentId: r.departmentId ? r.departmentId[0] : departmentId,

                });
            }
            let selectedworktype: any = []
            if (this.props.details && this.props.mode === 'edit') {

                let templateArr = this.props.details.workorderTypeIds;
                templateArr && templateArr.forEach((item: any) => {
                    let tempworktypeItems = worktypeItems.filter((r: any) => r.id === item);

                    tempworktypeItems.forEach((item: any) => {
                        selectedworktype.push(item.id);
                    });
                })
            }
            this.setState({ worktypeItems: worktypeItems, allworktypeItems: worktypeItems, woworktype: selectedworktype ? selectedworktype : '' });
        } catch (err) {
            console.log(err);
        }
    }
    private async _getUserData() {
        this.setState({ userDataLoading: true });
        try {
            let data: any = await utility.genericGetAPICall(Config.employeelistname);
            let UserItems = [];
            let selectedUserItem: any = '';
            UserItems = data.filter((r: any) => r.isActive === true).map((r: any) => ({
                id: r.id, text: r.fullName
            }));
            if (this.props.details && this.props.mode === 'edit') {
                selectedUserItem = UserItems.filter((r: any) => r.id === this.props.details.councilContactUserId);
            }
            this.setState({ UserItems: UserItems, allUserItems: UserItems, contactUser: selectedUserItem ? selectedUserItem[0] : '', userDataLoading: false });
        } catch (err) {
            console.log(err);
        }
    }
    private async _getTeamDataAsync() {

        try {
            let data: any = await utility.genericGetAPICall(Config.TeamList);
            this.teamsItems = [];
            let selectedTeams: any = [];
            this.teamsItems = data.map((r: any) => ({
                id: r.id, text: (r.code + ' - ' + r.title)
            }));
            if (this.props.details && this.props.mode === 'edit') {

                let templateArr = this.props.details.teamID;
                templateArr.forEach((item: any) => {
                    let tempteams = this.teamsItems.filter((r: any) => r.id === item);

                    tempteams.forEach((item: any) => {
                        selectedTeams.push(item.id);
                    });
                })
            }
            this.setState({
                teamsItems: this.teamsItems,
                allteamsItems: this.teamsItems, teams: selectedTeams
            });
        } catch (err) {
            console.log(err);
        }
    }

    public AddEditContractor() {
        let dataToPassToService: any = {};
        if (!this.state.name) {
            this.setState({
                alertMessage: 'Please enter Name',
                showAlert: true
            });
            return;
        }
        if (!this.state.email) {
            this.setState({
                alertMessage: 'Please enter Email',
                showAlert: true
            });
            return;
        }
        if (!this.state.woworktype) {
            this.setState({
                alertMessage: 'Please select a Activity',
                showAlert: true
            });
            return;
        }
        if (!this.state.pin) {
            this.setState({
                alertMessage: 'Please enter pin',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' type={'converging-spinner'} /> <br /> Loading...</div>,
            hideAlertButton: true,
            showAlert: true
        });
        let tempteam: any = [];
        if (this.state.teams) {
            this.state.teams.forEach((element: any) => {
                tempteam.push(element);
            });
        }
        let tempworktypes: any = [];
        if (this.state.woworktype) {
            this.state.woworktype.forEach((element: any) => {
                tempworktypes.push(element);
            });
        }

        dataToPassToService.name = this.state.name;
        dataToPassToService.tenantId = Config.tenantId;
        dataToPassToService.teamIds = tempteam;
        dataToPassToService.workorderTypeIds = tempworktypes;
        dataToPassToService.email = this.state.email;
        dataToPassToService.isActive = this.state.isActive;
        dataToPassToService.councilContactUserId = this.state.contactUser?.id;
        dataToPassToService.councilContactPhone = this.state.councilPhone;
        dataToPassToService.councilContactEmail = this.state.contactPersonEmail;
        dataToPassToService.phone = this.state.phone;
        dataToPassToService.businessName = this.state.businessName;
        dataToPassToService.pin = this.state.pin;
        dataToPassToService.notes = this.state.notes || '';
        dataToPassToService.isDeleted = false;
        dataToPassToService.updatedBy = this.props.currentUser.Email;
        dataToPassToService.updated = new Date();
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            utility.genericUpdateAPICall(Config.Contractor, this.state.id, dataToPassToService).then((data: any) => {
                this.setState({ showAlert: false });
                this.clearFilter();
                this.ClearStorageService.clearStorage();
                this.props.onClick();
                this.props.message("Contractor has been updated");

            }, (err: any) => {
                console.log(err);
                this.setState({ showAlert: false });
            });
        }
        else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.Contractor, dataToPassToService).then((data: any) => {
                this.setState({ showAlert: false });
                this.clearFilter();
                this.ClearStorageService.clearStorage();
                this.props.onClick();
                this.props.message("New Contractor has been Added");

            }, (err: any) => {
                console.log(err);
                this.setState({ showAlert: false });
            });
        }


    }
    public clearFilter() {
        this.setState({ name: '', teams: '', email: '', businessName: '', phone: '', woworktype: '', contactPersonEmail: '', councilPhone: '', contactUser: '' });
        this.props.onClose();

    }

    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {

        this.setState({ [fieldName]: event.value });

    }
    public handleDropdownChange = (e: any, fieldName: string) => {
        if (e.selectedItem !== null) {
            this.setState({
                [fieldName]: e.selectedItem
            });
        }
    }
    private _multiFieldChanged = (e: any, fieldName: string) => {

        let selectedTeams: any = [];
        let selectedTeam: any = [];
        if (fieldName === "woworktype" && e.value !== null) {

            e['value'].length > 0 && e['value'].forEach((element: any) => {
                selectedTeams = this.state.allworktypeItems.filter((a: any) => a.id === element);
                selectedTeams && selectedTeams[0].teamIds.map((item: any, index: any) => {
                    selectedTeam.push(item);
                })

            });
        }
        this.setState({ [fieldName]: e.value, teams: selectedTeam ? selectedTeam : '' });

    };
    public _textAreaChanged = (e: any, fieldName: string) => {
        this.setState({ [fieldName]: e.value });
    }


    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                < Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Contractor`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Business Name"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.businessName}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'businessName')}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Business Email *"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.email}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'email')}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Business Contact Name *"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.name}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'name')}
                                            required={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <NumberBoxInput
                                            stylingMode='underlined'
                                            label="Business Contact Number"
                                            value={this.state.phone ? this.state.phone : ''}
                                            onValueChanged={(e: any) =>
                                                this.handleValueChange(e, "phone")
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TagBox items={this.state.worktypeItems}
                                            valueExpr="id"
                                            value={this.state.woworktype ? this.state.woworktype : ''}
                                            searchEnabled={true}
                                            displayExpr="text"
                                            onValueChanged={(e: any) => this._multiFieldChanged(e, 'woworktype')}
                                            //onContentReady={(e: any) => this._fieldChanged(e, 'swms')}
                                            label='Work Type *' />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TagBox
                                            dataSource={this.state.teamsItems}
                                            valueExpr="id"
                                            value={this.state.teams ? this.state.teams : ''}
                                            searchEnabled={true}
                                            displayExpr="text"
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'teams')}
                                            //onContentReady={(e: any) => this._fieldChanged(e, 'swms')}
                                            label="Teams" />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Pin"
                                            mode="password"
                                            required={true}
                                            value={this.state.pin}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'pin')}
                                            onKeyDown={() => { }}
                                            disabled={this.state.userType === "Portal" ? true : false}
                                        />
                                    </Grid>
                                    {!this.state.userDataLoading &&
                                        <Grid item xs={12} sm={6}>
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={this.state.UserItems}
                                                selectedItem={this.state.contactUser}
                                                label='Council Contact'
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'contactUser')}
                                            />

                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Council Contact Email"
                                            mode="email"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.contactPersonEmail ? this.state.contactPersonEmail : ''}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'contactPersonEmail')}
                                            onKeyDown={() => { }}
                                            required={true}
                                        //disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NumberBoxInput
                                            stylingMode='underlined'
                                            label="Council Contact Number"
                                            value={this.state.councilPhone ? this.state.councilPhone : ''}
                                            onValueChanged={(e: any) =>
                                                this.handleValueChange(e, "councilPhone")
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextAreaInput
                                            label="Notes"
                                            value={this.state.notes}
                                            onValueChanged={(e: any) => this._textAreaChanged(e, 'notes')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                    </Grid>
                                </Grid>
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.AddEditContractor()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog >

            </div >

        );
    }

}

