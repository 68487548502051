import * as React from 'react';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import Spinner from '../common/reusableComponents/Spinner';
import AlertDialog from '../common/alertDialog';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssignDatatable from '../common/DataTable/AssignDatatable';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DeleteDialog from '../common/DeleteAlert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
export default class AssignVehicle extends React.Component<any, any> {
    public categoryItems: any = [];
    public VehicleItems: any = [];
    public VehicleOperatorItems: any = [];
    public _Vehiclescolumns: any;
    public myRef: any;
    public currentUser: any;
    constructor(props: any) {
        super(props);
        this._Vehiclescolumns = [
            {
                field: 'plantResourceCode',
                title: 'Plant #',
                width: 260,
                type: 'string'
            },
            {
                field: 'plantTitle',
                title: 'Plant Type',
                width: 160,
                type: 'string'
            },
            {
                field: 'operatorFullName',
                title: 'Vehicle Operator',
                width: 160,
                type: 'string'
            }
        ];
        this.AddAssignVehicle = this.AddAssignVehicle.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.viewDetials = this.viewDetials.bind(this);
        this.categoryItems = [];
        this.VehicleItems = [];
        this.VehicleOperatorItems = [];
        this.myRef = React.createRef();
        let currentDetail = localStorage.getItem("currentDetail");

        this.currentUser = {
            Email: currentDetail
        };

        //console.log(this.currentUser);
        this.state = {
            selectedItem: undefined,
            _Vehiclescolumns: this._Vehiclescolumns,
            disableVehicle: true,
            assignPlantItems: []
        };
    }

    public componentDidMount() {
        this._getEmployeeData();
        this._getVehicletypeData();
    }
    public viewDetials(item: any) {

        this.setState({
            details: item,
            showModal: true
        });
    }

    public didMount() {
        if (this.props.workorder && this.props.workorder.plantOperators) {
            var JSONPlantsData;
            const _plantWOItems: any = [];
            if (this.props.workorder.plantOperators !== null) {
                JSONPlantsData = JSON.parse(this.props.workorder.plantOperators);
                if (!Array.isArray(JSONPlantsData)) {

                    JSONPlantsData = [JSONPlantsData];
                }
                JSONPlantsData.forEach(plantdata => {
                    _plantWOItems.push({
                        operatorFullName: plantdata.operatorFullName ? plantdata.operatorFullName : '',
                        operatorId: plantdata.operatorId ? plantdata.operatorId : '',
                        plantResourceCode: plantdata.plantResourceCode ? plantdata.plantResourceCode : '',
                        plantTitle: plantdata.plantTitle ? plantdata.plantTitle : ''
                    });
                });
            }
            this.setState({ assignPlantItems: _plantWOItems, WorkOrderID: this.props.WorkOrderID });
        }
        this.setState({
            hideAlertButton: false,
            woid: this.props.workorder && this.props.workorder.Id
        });
    }
    private _getEmployeeData() {
        let dataToPassToService: any = {};
        dataToPassToService.isActive = true;
        this.setState({
            loading: true
        });
        // console.log("dataToPassToService", dataToPassToService);
        utility.genericGetAPICallForList(Config.SearchEmployeeApi, 'POST', dataToPassToService).then((data: any) => {
            let employeedata = [];
            employeedata = data
                .filter((r: any) => r.isActive === true && r.isPlantOperator === true)
                .map((r: any) => ({
                    id: r.id,
                    text: r.fullName,
                    employeeno: r.resourceCode,
                    loginName: r.loginName,
                    pin: r.pin,
                    roleId: r.roleId,
                    roleName: r.roleName,
                    email: r.email
                }));
            const sortedemployeedata = utility._sortItems(employeedata, 'title', false);

            this.setState({ VehicleOperatorItems: sortedemployeedata, callAfterInit: true, loading: false, preItems: sortedemployeedata });

        }, (err) => {
            console.log(err);
            this.setState({ loading: false, callAfterInit: true, });
        });
    }
    private _getVehicletypeData() {
        utility.genericGetAPICallForList(Config.EquipmentsListName)
            .then((data: any) => {
                const vehicleItems = data.map((item: any) => ({
                    id: item.id,
                    text: item.title,
                    category: item.category
                }));

                const categoryItems = [...new Set(data.map((item: any) => item.category))];
                this.categoryItems = categoryItems.map((item: any) => ({ id: item, text: item }));

                this.setState({
                    VehicleItems: vehicleItems,
                    allVehicleItems: vehicleItems,
                    categoryItems: this.categoryItems
                });
            })
            .catch((err) => {
                console.error(err);
            });
    }
    public DeleteDialog(item: any) {
        console.log("delete press");
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            deleteItem: item.data,
            deleteIndex: item.rowIndex
        });
    }
    public DeleteAssignVehicle(item: any) {
        let dataToPassToService: any = {};
        this.setState({
            alertMessage: <Spinner label='Deleting...' />,
            hideAlertButton: true,
            showAlert: true
        });
        let tempAssignPlantItems = JSON.parse(JSON.stringify(this.state.assignPlantItems));
        tempAssignPlantItems.splice(this.state.deleteIndex, 1);

        this.setState({ assignPlantItems: tempAssignPlantItems, loading: true });
        dataToPassToService.id = +this.props.WorkOrderID;
        dataToPassToService.plantOperators = JSON.stringify(tempAssignPlantItems);

        utility.genericUpdateAPICall(Config.UpdateWO, this.props.WorkOrderID, dataToPassToService).then((results: any) => {
            //console.log(data);showDeleteAlert: true,
            this.setState({ showAlert: false, showDeleteAlert: false, loading: false });
            this.props.onClick();
            this.clearFilter();
        }, (err: any) => {
            this.setState({ showAlert: false, showDeleteAlert: false, loading: false });
            console.log(err);
        });

    }
    public AddAssignVehicle() {
        let dataToPassToService: any = {};

        if (!this.state.category) {
            this.setState({
                alertMessage: 'Please select Category',
                showAlert: true,
                hideAlertButton: false
            });
            return;
        }
        if (!this.state.Vehicle) {
            this.setState({
                alertMessage: 'Please select Title',
                showAlert: true,
                hideAlertButton: false
            });
            return;
        }
        if (!this.state.VehicleOperator) {
            this.setState({
                alertMessage: 'Please select operator',
                showAlert: true,
                hideAlertButton: false
            });
            return;
        }
        this.setState({
            alertMessage: <Spinner label='Adding...' />,
            hideAlertButton: true,
            showAlert: true
        });
        this.setState({ loading: true });
        let tempAssignPlantItems = this.state.assignPlantItems ? this.state.assignPlantItems : [];
        tempAssignPlantItems.push({
            operatorFullName: this.state.VehicleOperator.text,
            operatorId: this.state.VehicleOperator.id,
            plantResourceCode: this.state.Vehicle.id,
            plantTitle: this.state.Vehicle.text
        });
        dataToPassToService.plantOperators = JSON.stringify(tempAssignPlantItems);
        dataToPassToService.id = this.props.WorkOrderID;
        dataToPassToService.updated = new Date();
        dataToPassToService.updatedBy = this.currentUser.Email;
        utility.genericUpdateAPICall(Config.WOdetailsSearchAPI, this.props.WorkOrderID, dataToPassToService).then((results: any) => {
            //console.log(data);
            this.setState({ showAlert: false, loading: false, hideAlertButton: false, });
            this.props.onClick();
            this.clearFilter();
        }, (err: any) => {
            this.setState({ showAlert: false, loading: false, hideAlertButton: false });
            console.log(err);
        });
    }

    public clearFilter() {
        this.setState({ category: '', VehicleOperator: '', Vehicle: '', assignPlantItems: '' });

    }
    private _fieldChanged = (event: any, fieldName: string, options?: any) => {
        this.setState({ [fieldName]: event.selectedItem });
        if (fieldName === 'category' && event.selectedItem) {
            let tempVehicleItems = this.state.allVehicleItems;
            let filteredVehicleItems = tempVehicleItems.filter((t: any) => t.category === event.selectedItem.id);
            this.setState({
                disableVehicle: false,
                VehicleItems: filteredVehicleItems,
                Vehicle: ''
            });
        }
    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }

    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                <Dialog
                    open={this.props.showModal}
                    onClose={this.clearFilter}
                    // className={'addEditModal'}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >

                    <DialogTitle id="scroll-dialog-title">Assign/Modify Plant/Vehicle</DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.props.onClose()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4} >
                                        <SelectBoxInput
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            showClearButton={true}
                                            items={this.state.categoryItems}
                                            selectedItem={this.state.category}
                                            label='Category'
                                            onSelectionChanged={(e: any) => this._fieldChanged(e, 'category', 'categoryItems')}
                                        />
                                    </Grid>
                                    {this.state.VehicleItems &&
                                        <Grid item xs={12} sm={4} >
                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                disabled={this.state.disableVehicle}
                                                items={this.state.VehicleItems ? this.state.VehicleItems : ''}
                                                selectedItem={this.state.Vehicle}
                                                label='Plant/Vehicle'
                                                onSelectionChanged={(e: any) => this._fieldChanged(e, 'Vehicle', 'VehicleItems')}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={3} >
                                        <SelectBoxInput
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            showClearButton={true}
                                            items={this.state.VehicleOperatorItems}
                                            selectedItem={this.state.VehicleOperator}
                                            label='Operator'
                                            onSelectionChanged={(e: any) => this._fieldChanged(e, 'VehicleOperator', 'VehicleOperatorItems')}
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={11}>
                                        <div>
                                            {!this.state.loading ?
                                                <div ref={this.myRef}>
                                                    {(this.state.assignPlantItems && this.state.assignPlantItems.length > 0) && (
                                                        <AssignDatatable
                                                            style={{ width: '100%' }}
                                                            filteralbe={true}
                                                            groupable={true}
                                                            rows={this.state.assignPlantItems}
                                                            columns={this.state._Vehiclescolumns}
                                                            fileName={'workorders'}
                                                            columnChooser={'workorderColumns'}
                                                            selectionMode={false}
                                                            showHideColumnMenu={true}
                                                            callAfterInit={this.state.callAfterInit}
                                                            stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                                            loading={this.state.loading}
                                                            deleteItem={(item: any) => this.DeleteDialog(item)}
                                                            allowExportSelecteditems={false}
                                                            showNewBtn={false}
                                                            hideCommandEditBtn={true}
                                                            showCommandBtn={true}
                                                        />
                                                    )}
                                                </div>
                                                :
                                                // <CircularProgress className="loader" />
                                                <Spinner size='large' label="Loading.." className="loader" />
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <DialogActions>
                        <Button onClick={() => this.props.onClose()} >
                            Close
                        </Button>
                        <Button onClick={this.clearFilter} variant='outlined' >
                            Clear
                        </Button>
                        <Button onClick={this.AddAssignVehicle} variant='outlined' color="primary">
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>

        );
    }

    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.DeleteAssignVehicle(this.state.deleteItem)}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }
}

