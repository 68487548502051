import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import AlertDialog from '../common/alertDialog';
import AssignDatatable from '../common/DataTable/AssignDatatable';
import DateBoxInput from '../common/reusableComponents/DateBox';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import TextBoxInput from '../common/reusableComponents/TextBox';
import Spinner from '../common/reusableComponents/Spinner';
import moment from 'moment-timezone';

let _items: any = [];
export default class InspectionForm extends React.Component<any, any> {

    public FormItems: any = [];
    public UserItems: any = [];
    public columns: any;

    constructor(props: any) {
        super(props);
        if (!this.props.Department) {
            window.open('#/AccessRequest', '_self');
        };
        this.FormItems = [];
        this.UserItems = [];
        this.getInspectionFormwithcriteria = this.getInspectionFormwithcriteria.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.clearFilter = this.clearFilter.bind(this);

        this.columns = [

            {
                field: 'fileName',
                title: 'File Name',
                // width: 160,
                type: 'string',
                allowHiding: false,
                link: 'file',
            },
            {
                field: 'title',
                title: 'Title',
                // width: 160,
                type: 'string',
                allowHiding: true,
            },
            {
                field: 'createdOn',
                title: 'Created',
                width: 160,
                type: 'datetime',
                allowHiding: true
            },
            {
                field: 'fileURL',
                title: 'File Url',
                width: 160,
                type: 'string',
                allowHiding: true
            },


            {
                field: 'createdBy',
                title: 'Created By',
                width: 160,
                type: 'string',
                allowHiding: true
            },
        ];

        this.state = {
            items: _items,
            columns: this.columns,
            callAfterInit: false,
            loading: false
        };
    }


    public componentDidMount() {
        this._getFormData();
        this._getUserData();
        let tempstate = localStorage.getItem('inspectionDatastate');
        if (tempstate) {
            let allState = JSON.parse(tempstate);
            console.log({ allState })
            this.setState({
                dateCreatedTo: allState.dateCreatedTo ? allState.dateCreatedTo : '',
                dateCreatedfrom: allState.dateCreatedfrom ? allState.dateCreatedfrom : '',
                form: allState.form ? allState.form : '',
                title: allState.title ? allState.title : '',
                user: allState.user ? allState.user : '',
                loadingFields: false
            })
        }

    }

    public _handleKeyDown = (e: any) => {
        if (e.event.originalEvent.key === 'Enter') {
            this.getInspectionFormwithcriteria();
        }
    }
    public clearFilter() {
        this.setState({
            items: '',
            dateCreatedTo: '',
            dateCreatedfrom: '',
            form: '',
            title: '',
            user: '',
        });
        localStorage.setItem('inspectionDatastate', '');
        let datagrid = localStorage.getItem('datagridFilterValue');
        if (datagrid) {
            localStorage.removeItem('datagridFilterValue');
        }
    }
    public viewFile(file: any) {
        let item: any = file;
        let dataToPassToService: any = {};
        if (!utility.validURL(item.fileURL) || !item.fileName) {
            this.setState({
                alertMessage: 'Unable to view this document. \n File URL or File name does exist',
                showAlert: true
            });
            return;
        }
        dataToPassToService.fileName = item.fileName;
        dataToPassToService.fileURL = item.fileURL;
        utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {
            //console.log("doc",doc);
            window.open(doc, "_blank");
        });

    }

    private _getFormData() {
        utility.genericGetAPICall(`${Config.FormsListName}?showAll=true`).then((data: any) => {
            //console.log(data);
            this.FormItems = [];
            for (let r of data) {
                this.FormItems.push({ id: r.id, text: r.title });
            }
            const sortedItems = utility._sortItems(this.FormItems, 'text', false);
            this.setState({ FormItems: sortedItems, allFormItems: sortedItems });

        }, (err) => {
            console.log(err);
        });
    }
    private _getUserData() {
        utility.genericGetAPICall(`${Config.employeelistname}?showAll=true`).then((data: any) => {
            //console.log(data);
            this.UserItems = [];
            for (let r of data) {
                this.UserItems.push({ id: r.id, text: r.fullName });
            }
            const sortedItems = utility._sortItems(this.UserItems, 'text', false);
            this.setState({ UserItems: sortedItems, allUserItems: sortedItems });

        }, (err) => {
            console.log(err);
        });
    }


    public getInspectionFormwithcriteria() {
        let criteriaSelected = false;

        let dataToPassToService: any = {};

        if (this.state.title) {
            dataToPassToService.title = this.state.title;
            criteriaSelected = true;
        }

        if (this.state.form) {
            dataToPassToService.formId = this.state.form.id;
            criteriaSelected = true;
        }
        if (this.state.status) {
            dataToPassToService.status = this.state.status.id;
            criteriaSelected = true;
        }
        if (this.state.user) {
            dataToPassToService.userId = this.state.user.id;
            criteriaSelected = true;
        }
        if (this.state.dateCreatedTo && this.state.dateCreatedfrom) {
            let dcreatedfrom = new Date(this.state.dateCreatedfrom);
            let dcreatedTo = new Date(this.state.dateCreatedTo);
            if (dcreatedTo.getTime() < dcreatedfrom.getTime()) {
                this.setState({
                    alertMessage: 'Date To should be greater than Date From',
                    showAlert: true
                });
                return;
            }
        }

        if (this.state.dateCreatedfrom || this.state.dateCreatedTo) {
            if (this.state.dateCreatedfrom) {
                const startOfDay = moment(this.state.dateCreatedfrom).startOf('day');
                const startOfDayUTC = startOfDay.utc();
                dataToPassToService.dateFrom = startOfDayUTC.toISOString();
            }
            if (this.state.dateCreatedTo) {
                const endOfDay = moment(this.state.dateCreatedTo).endOf('day');
                const endOfDayUTC = endOfDay.utc();
                dataToPassToService.dateTo = endOfDayUTC.toISOString();
            }
            criteriaSelected = true;
        }

        if (!criteriaSelected) {
            this.setState({
                alertMessage: 'Please select search criteria',
                showAlert: true
            });
            return;
        }

        this.setState({
            loading: true
        });

        utility.genericPostAPIcall(Config.SearchFormsSubmitted, dataToPassToService).then((data: any) => {
            _items = [];
            if (data === null || data.length <= 0) {
                this.setState({
                    alertMessage: 'No results found for this criteria',
                    showAlert: true
                });
                this.setState({ items: _items, loading: false });
                return;
            }
            else {
                data.forEach((item: any) => {

                    _items.push({
                        id: item.id,
                        fileName: item.formName,
                        formTitle: item.formTitle,
                        title: item.title,
                        fileURL: item.fileUrl,
                        workorderId: item.workorderId,
                        crewName: item.crewName,
                        userId: item.userId,
                        createdBy: item.submittedBy,
                        createdOn: item.created
                    });
                });

            }
            _items = utility._sortItems(_items, 'createdOn', true);
            if (_items.length <= 0) {
                this.setState({
                    alertMessage: 'No results found for this criteria',
                    showAlert: true
                });
            }

            this.setState({ items: _items, loading: false });
            var stateData = { ...this.state };
            localStorage.setItem('inspectionDatastate', JSON.stringify(stateData));
            //console.log(_items);
        }, (err) => {
            console.log(err);
            this.setState({ loading: false });
        });
    }


    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    public render(): React.ReactElement<any> {

        return (
            <div >
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}>Inspection Forms</span>
                    </div>
                </div>
                <div className={"searchFieldDiv"}>
                    <Grid container spacing={3} >
                        <Grid item xs={12} sm={6} md={6} lg={4}>

                            <DateBoxInput
                                stylingMode='underlined'
                                type="date"
                                displayFormat={"dd/MM/yy"}
                                label={'Date From'}
                                showClearButton={true}
                                value={this.state.dateCreatedfrom ? new Date(this.state.dateCreatedfrom) : null}
                                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedfrom')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>

                            <DateBoxInput
                                stylingMode='underlined'
                                type="date"
                                displayFormat={"dd/MM/yy"}
                                label={'Date To'}
                                showClearButton={true}
                                value={this.state.dateCreatedTo ? new Date(this.state.dateCreatedTo) : null}
                                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedTo')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <TextBoxInput
                                stylingMode='underlined'
                                label="Title"
                                // pattern={"[A-Za-z]+"}
                                // minLength={2}
                                value={this.state.title}
                                onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                onKeyDown={this._handleKeyDown}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <SelectBoxInput
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                label='Form'

                                items={this.state.FormItems}
                                selectedItem={this.state.form}
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'form')}

                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <SelectBoxInput
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                label='User'
                                items={this.state.UserItems}
                                selectedItem={this.state.user}
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'user')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <div className={"searchButtonDiv"}>

                                <Button onClick={this.clearFilter} variant='outlined'>
                                    Clear
                                </Button>
                                <Button onClick={this.getInspectionFormwithcriteria} variant='outlined' color="primary" className={'button'}>
                                    Search
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>

                    {!this.state.loading ?
                        <div className='tabStripDiv'>
                            {(this.state.items && this.state.items.length > 0) && (
                                <AssignDatatable
                                    style={{ width: '100%' }}
                                    filteralbe={true}
                                    groupable={true}
                                    rows={this.state.items}
                                    columns={this.state.columns}
                                    fileName={'Inspection Form'}
                                    columnChooser={'inspectionformColumns'}
                                    selectionMode={false}
                                    showHideColumnMenu={true}
                                    callAfterInit={this.state.callAfterInit}
                                    stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                    loading={this.state.loading}
                                    allowExportSelectedData={false}
                                    showNewBtn={false}
                                    // showNewModal = {() => this._showModal()}
                                    // deleteItem = {(a:any) => this.DeleteDialog(a)}
                                    // editItem = {(a:any) => this.EditItemModal(a)}
                                    viewFile={(e: any) => this.viewFile(e)}
                                    showCommandBtn={false}
                                    showViewDetailBtn={false}
                                />
                            )}
                        </div>
                        :
                        // <CircularProgress className="loader" />
                        <Spinner size='large' label={"Loading.."} />
                    }
                </div>


            </div>
        );
    }

    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }



}
